"use strict"

import React, {useEffect, useState} from "react"
import {observer} from "mobx-react"
import moment from "moment"
import {injectIntl} from "react-intl"
import {Container, Box, Grid, Typography} from "@material-ui/core"
// import XsButton from "../../global/ui/xsButton/xsButton"
import XsTable from "../../global/ui/xsTable/xsTable"
import RequestStore from "./requestStore"
import GlobalStore from "../../global/store/GlobalStore"
import RouterStore from "../../global/store/RouterStore"
import fields from "./filterFields"
import reqFields from "../RequestForm/fields"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import dvr from "mobx-react-form/lib/validators/DVR"
import XsInput from "../../global/ui/xsInput/xsInput"
import XsSearchSelect from "../../global/ui/xsSearchSelect/xsSearchSelect"
import XsDateTimePicker from "../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsIconButton from "../../global/ui/xsButton/xsIconButton"
import XsButton from "../../global/ui/xsButton/xsButton"

function Requests() {
	const hooks = {
		onSubmit() {},
		onSuccess(form) {
			RequestStore.getRequests(form)
		},
		onError() {
			GlobalStore.setNotificationMessage("Skontrolujte povinné polia", null, "error")
		}
	}

	let [form] = useState(new MobxReactForm(fields.load(), {plugins: {dvr: dvr(validatorjs)}, hooks}))
	if (isSafe(RequestStore.filterFormRef)) {
		form = RequestStore.filterFormRef
	}
	RequestStore.filterFormRef = form
	//fiktivny form pre load dat z requestu pre export do PDF a excelu
	const [requestForm] = useState(new MobxReactForm(reqFields.load(), {plugins: {dvr: dvr(validatorjs)}, hooks}))

	const columns = [
		{
			Header: "Dátum odoslania žiadosti",
			width: 90,
			accessor: "sent_date_time",
			sortType: (rowA, rowB) => {
				const send_date_time_A = isNotEmpty(rowA.original.sent_date_time) ? rowA.original.sent_date_time : null
				const send_date_time_B = isNotEmpty(rowB.original.sent_date_time) ? rowB.original.sent_date_time : null

				if (isNotSafe(send_date_time_A) && isNotSafe(send_date_time_B)) {
					return 0
				}
				if (isNotSafe(send_date_time_A)) {
					return -1
				}

				if (isNotSafe(send_date_time_B)) {
					return 1
				}

				return moment(send_date_time_A).diff(moment(send_date_time_B))
			},
			Cell: (props) => <div>{isNotEmpty(props.value) ? moment(props.value).format("DD.MM.YYYY HH:mm") : ""}</div>
		},
		{
			Header: "Kód Výzvy",
			width: 80,
			accessor: "call_type_code_ext"
		},
		{
			Header: "IČO",
			width: 80,
			accessor: "company_identifier"
		},
		{
			Header: "Obchodné meno",
			width: 120,
			accessor: "company_name",
			sortType: (rowA, rowB) => {
				const company_name_A = isNotEmpty(rowA.original.company_name) ? rowA.original.company_name : null
				const company_name_B = isNotEmpty(rowB.original.company_name) ? rowB.original.company_name : null

				if (isNotSafe(company_name_A) && isNotSafe(company_name_B)) {
					return 0
				}
				if (isNotSafe(company_name_A)) {
					return -1
				}

				if (isNotSafe(company_name_B)) {
					return 1
				}

				return company_name_A.localeCompare(company_name_B)
			}
		},
		{
			Header: "Číslo ŽoIP",
			width: 120,
			accessor: "innovation_request_id"
		},
		{
			// Header: "Požadovaná suma",
			Header: () => (
				<div
					style={{
						textAlign: "right"
					}}
				>
					Požadovaná suma
				</div>
			),
			width: 60,
			accessor: "innovation_voucher_amount",
			Cell: (row) => (
				<div style={{textAlign: "right"}}>
					{row.value} {isNotEmpty(row.value) ? "€" : ""}
				</div>
			)
		},
		{
			// Header: "Stav žiadosti",
			Header: () => (
				<div
					style={{
						textAlign: "right"
					}}
				>
					Stav žiadosti
				</div>
			),
			width: 100,
			accessor: "status_name_ext",
			Cell: (row) => <div style={{textAlign: "right"}}>{row.value}</div>
		},
		{
			clickable: false,
			width: 120,
			accessor: "record_id",
			Cell: (obj) => {
				const hasAttachment = isSafe(obj.row.original.attachments) && obj.row.original.attachments.length > 0

				const typeNameStringStart = obj.row.original.call_type_code_ext == "IP_PTM_125" ? "INN:IPPTM125" : "INN:IPPC124"

				return (
					<React.Fragment>
						{isNotEmpty(obj.value) && (
							<Grid container direction={"row"} justify="flex-end" spacing={"2"}>
								{hasAttachment && (
									<React.Fragment>
										{obj.row.original.attachments.some(
											(row) => row.type === typeNameStringStart + ":PRICERESEARCH"
										) && (
											<Grid
												item
												onClick={() =>
													RequestStore.downloadAttachmentsForRow(
														obj.row.original.attachments,
														typeNameStringStart + ":PRICERESEARCH"
													)
												}
											>
												<span className="pointer blueDark bold" style={{fontSize: "18px"}}>
													1
												</span>
											</Grid>
										)}
										{obj.row.original.attachments.some((row) => row.type === typeNameStringStart + ":ORCONTRACT") && (
											<Grid
												item
												onClick={() =>
													RequestStore.downloadAttachmentsForRow(
														obj.row.original.attachments,
														typeNameStringStart + ":ORCONTRACT"
													)
												}
											>
												<span className="pointer blueDark bold" style={{fontSize: "18px"}}>
													2
												</span>
											</Grid>
										)}
										{obj.row.original.attachments.some((row) => row.type === typeNameStringStart + ":BUACCONTRACT") && (
											<Grid
												item
												onClick={() =>
													RequestStore.downloadAttachmentsForRow(
														obj.row.original.attachments,
														typeNameStringStart + ":BUACCONTRACT"
													)
												}
											>
												<span className="pointer blueDark bold" style={{fontSize: "18px"}}>
													3
												</span>
											</Grid>
										)}
									</React.Fragment>
								)}
								<Grid item>
									<i
										className="fas fa-file-pdf fa-lg pointer blueDark"
										onClick={() => RequestStore.generatePDF(requestForm, obj.value)}
									></i>
								</Grid>
								<Grid item>
									<i
										className="fas fa-file-excel fa-lg pointer blueDark"
										onClick={() => RequestStore.generateExcel(requestForm, obj.value)}
									></i>
								</Grid>
							</Grid>
						)}
					</React.Fragment>
				)
			}
		}
	]

	useEffect(() => {
		RequestStore.getCallItems()
		RequestStore.loadCodeListsAndGetRequests(form)
	}, [])

	return (
		<Container maxWidth="lg">
			<Grid container direction="column">
				<Grid item>
					<Box pt={2} pb={2}>
						<Typography variant="h4" className="header_gradient">
							Zoznam žiadostí
						</Typography>
					</Box>
				</Grid>
			</Grid>
			<Grid container direction="row-reverse" className="mt-2" item xs={12} spacing={1}>
				<Grid item>
					<XsButton
						icon={<i className="fal fa-file-excel"></i>}
						onClick={() => {
							RequestStore.exportListToXls(form, true)
						}}
						variant="outlined"
						disabled={isEmpty(form.$("callCode").value)}
						text={"EXPORT - OPIS"}
					/>
				</Grid>
				<Grid item>
					<XsButton
						icon={<i className="fal fa-file-excel"></i>}
						onClick={() => {
							RequestStore.exportListToXls(form, false)
						}}
						variant="outlined"
						disabled={isEmpty(form.$("callCode").value)}
						text={"EXPORT DO XLS"}
					/>
				</Grid>
			</Grid>
			<Box pt={2}>
				<Grid container>
					{/* <Grid item xs={12} container justify="flex-end">
						<Grid item>
							<XsButton
								disabled={GlobalStore.isRegistrationClose()}
								text="New IPCEI Registration form (application)"
								icon={<i className="fas fa-plus"></i>}
								onClick={() => RouterStore.push("/request")}
							/>
						</Grid>
					</Grid> */}
					<Grid container item xs={12} spacing={1}>
						<Grid item xs={2}>
							<XsDateTimePicker field={form.$("dateFrom")} />
						</Grid>
						<Grid item xs={2}>
							<XsDateTimePicker field={form.$("dateTo")} />
						</Grid>
						<Grid item xs={2}>
							<XsSearchSelect
								field={form.$("callCode")}
								getOptionLabel={(row) => row.code_ext}
								items={RequestStore.callItems}
							/>
						</Grid>
						<Grid item xs={2}>
							<XsSearchSelect
								field={form.$("callStatus")}
								getOptionLabel={(row) => row.name_ext}
								items={GlobalStore.CL["requestStatus"]}
							/>
						</Grid>
						<Grid item xs={2}>
							<XsInput field={form.$("ico")} />
						</Grid>
						<Grid item xs={1}>
							<XsInput field={form.$("zoipNumber")} />
						</Grid>
						<Grid item direction="row">
							<XsIconButton
								rect
								className="xs-default xs-outline fa-pull-right"
								tooltip={"Aplikovať filtre"}
								onClick={form.onSubmit}
								icon={
									<i
										className="far fa-search fa-lg xs-greyDefault"
										style={{paddingTop: "8px", paddingBottom: "8px", paddingLeft: "3px", paddingRight: "3px"}}
									></i>
								}
							/>
							<XsIconButton
								rect
								className="xs-default xs-outline fa-pull-right mr-1"
								tooltip={"Resetovať filtre"}
								variant="outlined"
								color="primary"
								onClick={(e) => {
									form.reset()
									form.onSubmit(e)
								}}
								icon={
									<i
										className="far fa-times fa-lg xs-greyDefault"
										style={{paddingTop: "8px", paddingBottom: "8px", paddingLeft: "3px", paddingRight: "3px"}}
									></i>
								}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Box pt={1}>
							<XsTable
								columns={columns}
								data={RequestStore.data}
								onRowClick={(row) => RouterStore.push(`/request?id=${row.record_id}`)}
							/>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</Container>
	)
}

export default injectIntl(observer(Requests))

