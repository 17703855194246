import {action} from "mobx"
import {printReport} from "../../global/helpers/actions"
import api from "../../global/actions/api"
import GlobalStore from "../../global/store/GlobalStore"
import moment from "moment"

class RequestPrintStore {
	@action print(form) {
		const formVals = form.values()

		let pravnickaOsoba = ""
		if (isNotEmpty(formVals.companyDesc)) {
			let type = GlobalStore.CL["companyType"].find((row) => row._id == formVals.companyDesc)
			if (isSafe(type)) {
				pravnickaOsoba = type.name_ext
			}
		}

		let adresaObec = ""
		if (isNotEmpty(formVals.companyCity)) {
			let address = GlobalStore.CL["city"].find((row) => row._id == formVals.companyCity)
			if (isSafe(address)) {
				adresaObec = address.name_ext
			}
		}

		let adresaKraj = ""
		if (isNotEmpty(formVals.companyRegion)) {
			let address = GlobalStore.CL["region"].find((row) => row._id == formVals.companyRegion)
			if (isSafe(address)) {
				adresaKraj = address.name_ext
			}
		}

		let adresaCorspondingObec = ""
		if (isNotEmpty(formVals.companyCity)) {
			let address = GlobalStore.CL["city"].find((row) => row._id == formVals.companyCorrespondenceCity)
			if (isSafe(address)) {
				adresaCorspondingObec = address.name_ext
			}
		}

		let pravnaForma = ""
		if (isNotEmpty(formVals.companyLegalForm)) {
			let forma = GlobalStore.CL["legalForm"].find((row) => row._id == formVals.companyLegalForm)
			if (isSafe(forma)) {
				pravnaForma = forma.name_ext
			}
		}

		let companySize = ""
		if (isNotEmpty(formVals.companySize)) {
			let codeListObj = GlobalStore.CL["companySize"].find((row) => row._id == formVals.companySize)
			if (isSafe(codeListObj)) {
				companySize = codeListObj.name_ext
			}
		}

		let typOpravnenychAktivit = ""
		if (isNotEmpty(formVals.projectActivityType)) {
			let aktivity = GlobalStore.CL["activityType"].find((row) => row._id == formVals.projectActivityType)
			if (isSafe(aktivity)) {
				typOpravnenychAktivit = aktivity.name_ext
			}
		} else if (isNotEmpty(formVals.eligibleArea)) {
			let aktivity = GlobalStore.CL["activityType"].find((row) => row._id == formVals.eligibleArea)
			if (isSafe(aktivity)) {
				typOpravnenychAktivit = aktivity.name_ext
			}
		}

		let opravneneKategorie = ""
		if (isNotEmpty(formVals.eligibleCategories)) {
			let aktivity = GlobalStore.CL["eligibleCategory"].find((row) => row._id == formVals.eligibleCategories)
			if (isSafe(aktivity)) {
				opravneneKategorie = aktivity.name_ext
			}
		}

		let opravnenaOblast = ""
		if (isNotEmpty(formVals.eligibleArea)) {
			let aktivity = GlobalStore.CL["adviceArea"].find((row) => row._id == formVals.eligibleArea)
			if (isSafe(aktivity)) {
				opravnenaOblast = aktivity.name_ext
			}
		}

		let printValues = {
			obchodne_meno: formVals.companyName,
			zoip: formVals.zoIPNumber,
			customFileName: formVals.zoIPNumber,
			pravnicka_osoba: pravnickaOsoba,
			adresa_ulica: formVals.companyStreet,
			adresa_popisne_cislo: formVals.companyStreetNumber,
			adresa_obec: adresaObec,
			adresa_psc: formVals.companyPSC,
			adresa_kraj: adresaKraj,
			koresp_adresa_ulica: isNotEmpty(formVals.companyCorrespondenceStreet)
				? formVals.companyCorrespondenceStreet
				: formVals.companyStreet,
			koresp_adresa_popisne_cislo: isNotEmpty(formVals.companyCorrespondenceStreetNumber)
				? formVals.companyCorrespondenceStreetNumber
				: formVals.companyStreetNumber,
			koresp_adresa_obec: isNotEmpty(adresaCorspondingObec) ? adresaCorspondingObec : adresaObec,
			koresp_adresa_psc: isNotEmpty(formVals.companyCorrespondencePSC)
				? formVals.companyCorrespondencePSC
				: formVals.companyPSC,
			pravna_forma: pravnaForma,
			ic_dph: formVals.icdph,
			ico: formVals.ico,
			dic: formVals.dic,
			iban: formVals.companyIBAN,
			kontakt_meno: formVals.contactPersonName,
			email: formVals.contactPersonMail,
			telefon: formVals.contactPersonPhone,
			project_name: formVals.projectName,
			typ_opravnenych_aktivit: typOpravnenychAktivit,
			opravnene_kategorie: opravneneKategorie,
			opravnena_oblast: opravnenaOblast,
			strucny_popis_projektu: formVals.projectDescAndTarget,
			vychodiskova_situacia: formVals.projectBasicParameters,
			sposob_realizacie_projektu: formVals.projectImplementationMethod,
			doplnujuce_udaje: formVals.projectAdditionalInfo,
			potentialSolverName: formVals.potentialSolverName,
			potentialSolverIco: formVals.potentialSolverIco,
			potentialSolverDic: formVals.potentialSolverDic,
			potentialSolverIcdph: formVals.potentialSolverIcdph,
			potentialSolverCompletePrice: formVals.potentialSolverCompletePrice,
			potentialSolverVoucherPrice: formVals.potentialSolverVoucherPrice,
			potentialSolverCofinancedPrice: formVals.potentialSolverCofinancedPrice,
			sendDate: formVals.sent_date ? moment(formVals.sent_date).format("DD.MM.YYYY HH:mm") : "",
			consent_marketing_text: formVals.sentMarketingDataYes == true ? "ÁNO súhlasím" : "NIE, nesúhlasím",
			miesto_realizacie_projektu: formVals.projectRealizationPlace,
			lehota_plnenia_projektu: formVals.projectDeadlineCompletion,
			velkost_podniku: companySize || "",
			opravnene_aktivity: formVals.legitimateActivities,
			jeden_podnik: formVals.oneCompanySubjectsIdentification,
			pomoc_ine_zdroje: formVals.otherSourcesSupportCheckboxYes ? "Áno" : "Nie"
		}

		let statutari_tr = ""
		//Statutari
		let statutarData = isEmpty(formVals.applicantChipForm) ? [] : JSON.parse(formVals.applicantChipForm)
		statutarData.map((statutar) => {
			statutari_tr += `
			<tr>
				<td class="td_80 bg_blueLight center">${statutar.name}
				<td class="td_20 bold center">${statutar.isStatutar ? "x" : ""}</td>
			</tr>`
		})

		printValues["statutarny_organ_ziadatela_table"] = `<table style="margin-top: 20px;">
			<tr>
				<td class="td_80 bg_blue bold blue center" style="font-size: 13px;">2. Štatutárny orgán Žiadateľa / Fyzická osoba Žiadateľa</td>
				<td class="td_20 bg_blue bold blue center" style="font-size: 13px;">Zmluvu IP podpíše</td>
			</tr>
			${statutari_tr}
		</table><div><br/></div>`

		printValues["oblast_poradenstva"] =
			formVals.ipceiAdvice1 == true
				? "Poradenské služby zamerané na tvorbu dokumentácie pre proces notifikácie IPCEI - Vytvorenie dokumentu Project Portfolio podľa templatu, ktorý je definovaný EK a je súčasťou dokumentácie Výzvy IPCEI H2, dostupné na https://www.siea.sk/inovacie/projekty-ipcei/vyzva-ipcei-2020/ a Výzvy IPCEI mikrolektronika, dostupné na https://www.siea.sk/inovacie/projekty-ipcei/vyzva-ipcei-2021/ vrátane Funding Gap Questionnaire – template EK."
				: "Poradenské služby zamerané na tvorbu dokumentácie pre proces notifikácie IPCEI – Vybrané jednotlivé kapitoly dokumentu Project Portfolio, alebo Funding Gap Questionnaire podľa výberu a potreby Žiadateľa."

		if (formVals.callCode === "IP_PTM_125") {
			printReport("IP_PTM_125", printValues)
		} else {
			printReport("ipPC124", printValues)
		}
	}

	@action generateExcel(form) {
		const formVals = form.values()

		let pravnickaOsoba = ""
		if (isNotEmpty(formVals.companyDesc)) {
			let type = GlobalStore.CL["companyType"].find((row) => row._id == formVals.companyDesc)
			if (isSafe(type)) {
				pravnickaOsoba = type.name_ext
			}
		}

		let adresaObec = ""
		if (isNotEmpty(formVals.companyCity)) {
			let address = GlobalStore.CL["city"].find((row) => row._id == formVals.companyCity)
			if (isSafe(address)) {
				adresaObec = address.name_ext
			}
		}

		let adresaKraj = ""
		if (isNotEmpty(formVals.companyRegion)) {
			let address = GlobalStore.CL["region"].find((row) => row._id == formVals.companyRegion)
			if (isSafe(address)) {
				adresaKraj = address.name_ext
			}
		}

		let adresaCorspondingObec = ""
		if (isNotEmpty(formVals.companyCity)) {
			let address = GlobalStore.CL["city"].find((row) => row._id == formVals.companyCorrespondenceCity)
			if (isSafe(address)) {
				adresaCorspondingObec = address.name_ext
			}
		}

		let pravnaForma = ""
		if (isNotEmpty(formVals.companyLegalForm)) {
			let forma = GlobalStore.CL["legalForm"].find((row) => row._id == formVals.companyLegalForm)
			if (isSafe(forma)) {
				pravnaForma = forma.name_ext
			}
		}

		let typOpravnenychAktivit = ""
		if (isNotEmpty(formVals.projectActivityType)) {
			let aktivity = GlobalStore.CL["activityType"].find((row) => row._id == formVals.projectActivityType)
			if (isSafe(aktivity)) {
				typOpravnenychAktivit = aktivity.name_ext
			}
		}

		if (isNotEmpty(formVals.eligibleArea)) {
			let aktivity = GlobalStore.CL["activityType"].find((row) => row._id == formVals.eligibleArea)
			if (isSafe(aktivity)) {
				typOpravnenychAktivit = aktivity.name_ext
			}
		}

		let opravneneKategorie = ""
		if (isNotEmpty(formVals.eligibleCategories)) {
			let aktivity = GlobalStore.CL["eligibleCategory"].find((row) => row._id == formVals.eligibleCategories)
			if (isSafe(aktivity)) {
				opravneneKategorie = aktivity.name_ext
			}
		}

		let opravnenaOblast = ""
		if (isNotEmpty(formVals.eligibleArea)) {
			let aktivity = GlobalStore.CL["adviceArea"].find((row) => row._id == formVals.eligibleArea)
			if (isSafe(aktivity)) {
				opravnenaOblast = aktivity.name_ext
			}
		}

		let fileName = formVals.callCode == "IP_PTM_125" ? "ZoIP_PTM_125.xlsx" : "ZoIP_IP_PC_ZIVSE2.xlsx"

		let oblast_poradenstva =
			formVals.ipceiAdvice1 == true
				? "Poradenské služby zamerané na tvorbu dokumentácie pre proces notifikácie IPCEI - Vytvorenie dokumentu Project Portfolio podľa templatu, ktorý je definovaný EK a je súčasťou dokumentácie Výzvy IPCEI H2, dostupné na https://www.siea.sk/inovacie/projekty-ipcei/vyzva-ipcei-2020/ a Výzvy IPCEI mikrolektronika, dostupné na https://www.siea.sk/inovacie/projekty-ipcei/vyzva-ipcei-2021/ vrátane Funding Gap Questionnaire – template EK."
				: "Poradenské služby zamerané na tvorbu dokumentácie pre proces notifikácie IPCEI – Vybrané jednotlivé kapitoly dokumentu Project Portfolio, alebo Funding Gap Questionnaire podľa výberu a potreby Žiadateľa."

		let companySize = ""
		if (isNotEmpty(formVals.companySize)) {
			let codeListObj = GlobalStore.CL["companySize"].find((row) => row._id == formVals.companySize)
			if (isSafe(codeListObj)) {
				companySize = codeListObj.name_ext
			}
		}

		let req = {
			file_name: fileName,
			data: [
				{
					key: "obchodne_meno",
					value: formVals.companyName
				},
				{
					key: "cislo_zoip",
					value: formVals.zoIPNumber
				},
				{
					key: "nazov_projektu",
					value: formVals.projectName
				},
				{
					key: "pravnicka_osoba",
					value: pravnickaOsoba
				},
				{
					key: "adresa_ulica",
					value: formVals.companyStreet
				},
				{
					key: "adresa_popisne_cislo",
					value: formVals.companyStreetNumber
				},
				{
					key: "adresa_obec",
					value: adresaObec
				},
				{
					key: "adresa_psc",
					value: formVals.companyPSC
				},
				{
					key: "adresa_kraj",
					value: adresaKraj
				},
				{
					key: "koresp_adresa_ulica",
					value: isNotEmpty(formVals.companyCorrespondenceStreet)
						? formVals.companyCorrespondenceStreet
						: formVals.companyStreet
				},
				{
					key: "koresp_adresa_popisne_cislo",
					value: isNotEmpty(formVals.companyCorrespondenceStreetNumber)
						? formVals.companyCorrespondenceStreetNumber
						: formVals.companyStreetNumber
				},
				{
					key: "koresp_adresa_obec",
					value: isNotEmpty(adresaCorspondingObec) ? adresaCorspondingObec : adresaObec
				},
				{
					key: "koresp_adresa_psc",
					value: isNotEmpty(formVals.companyCorrespondencePSC) ? formVals.companyCorrespondencePSC : formVals.companyPSC
				},
				{
					key: "pravna_forma",
					value: pravnaForma
				},
				{
					key: "ic_dph",
					value: formVals.icdph
				},
				{
					key: "ico",
					value: formVals.ico
				},
				{
					key: "dic",
					value: formVals.dic
				},
				{
					key: "iban",
					value: formVals.companyIBAN
				},
				{
					key: "kontakt_meno",
					value: formVals.contactPersonName
				},
				{
					key: "email",
					value: formVals.contactPersonMail
				},
				{
					key: "telefon",
					value: formVals.contactPersonPhone
				},
				// {
				// 	key: "nazov_projektu",
				// 	value: formVals.projectName
				// },
				{
					key: "typ_aktivit",
					value: typOpravnenychAktivit
				},
				{
					key: "strucny_popis",
					value: htmlToPlain(formVals.projectDescAndTarget)
				},
				{
					key: "situacia",
					value: htmlToPlain(formVals.projectBasicParameters)
				},
				{
					key: "sposob_realizacie",
					value: htmlToPlain(formVals.projectImplementationMethod)
				},
				{
					key: "dopln_udaje",
					value: htmlToPlain(formVals.projectAdditionalInfo)
				},
				{
					key: "bp_meno",
					value: formVals.potentialSolverName
				},
				{
					key: "bp_ico",
					value: formVals.potentialSolverIco
				},
				{
					key: "bp_dic",
					value: formVals.potentialSolverDic
				},
				{
					key: "bp_icdph",
					value: formVals.potentialSolverIcdph
				},
				{
					key: "celkova_cena",
					value: formVals.potentialSolverCompletePrice
				},
				{
					key: "hodnota_ip",
					value: formVals.potentialSolverVoucherPrice
				},
				{
					key: "spolufinancovanie",
					value: formVals.potentialSolverCofinancedPrice
				},
				{
					key: "oblast_poradenstva",
					value: oblast_poradenstva
				},
				{
					key: "consent_marketing_information",
					value: formVals.sentMarketingDataYes == true ? "ÁNO súhlasím" : "NIE, nesúhlasím"
				},
				{
					key: "miesto_realizacie",
					value: htmlToPlain(formVals.projectRealizationPlace)
				},
				{
					key: "lehota_plnenia",
					value: htmlToPlain(formVals.projectDeadlineCompletion)
				},
				{
					key: "velkostna_kategoria_podniku",
					value: companySize
				},
				{
					key: "aktivity_zamerania",
					value: htmlToPlain(formVals.legitimateActivities)
				},
				{
					key: "ident_subjekt_jediny_podnik",
					value: htmlToPlain(formVals.oneCompanySubjectsIdentification)
				},
				{
					key: "pomoc_z_inych_zdrojov",
					value: formVals.otherSourcesSupportCheckboxYes ? "Áno" : "Nie"
				},
				{
					key: "datum_odoslania_ziadosti",
					value: formVals.sent_date ? moment(formVals.sent_date).format("DD.MM.YYYY HH:mm") : ""
				},
				{
					key: "opravnena_kategoria",
					value: opravneneKategorie
				},
				{
					key: "opravnena_oblast",
					value: opravnenaOblast
				}
			]
		}

		//Statutari
		let statutarData = isEmpty(formVals.applicantChipForm) ? [] : JSON.parse(formVals.applicantChipForm)
		statutarData.map((statutar, index) => {
			req["data"].push({
				key: "statutar" + (index + 1),
				value: statutar.name
			})
			req["data"].push({
				key: "podpise" + (index + 1),
				value: statutar.isStatutar ? "x" : ""
			})
			//podpise1
		})

		api
			.exportToXLS(req)
			.call()
			.then((res) => {
				if (res.ok == true) {
					let fileName = formVals.zoIPNumber ? formVals.zoIPNumber : "export"
					// isSafe(res.headers.get("content-disposition"))
					// 	? res.headers.get("content-disposition").split("filename=")[1]
					// 	:
					res.blob().then((blob) => {
						let FileSaver = require("file-saver") // eslint-disable-line no-undef
						FileSaver.saveAs(blob, fileName)
					})
				}
			})
	}
}

var singleton = new RequestPrintStore()
export default singleton

