import {Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@material-ui/core"
import React, {useEffect, useState} from "react"
import {injectIntl} from "react-intl"
import XsButton from "../../global/ui/xsButton/xsButton"
import GlobalStore from "../../global/store/GlobalStore"
import MobxReactForm from "mobx-react-form"
import fields from "./callRoundFields"
import validatorjs from "validatorjs"
import dvr from "mobx-react-form/lib/validators/DVR"
import {observer} from "mobx-react"
import CallRoundDialogStore from "./callRoundDialogStore"
import XsInput from "../../global/ui/xsInput/xsInput"
import XsSearchSelect from "../../global/ui/xsSearchSelect/xsSearchSelect"
import XsDateTimePicker from "../../global/ui/xsDateTimePicker/xsDateTimePicker"

function CallRoundDialog() {
	const hooks = {
		onSubmit() {},
		onSuccess(form) {
			CallRoundDialogStore.addCallRound(form)
		},
		onError() {
			GlobalStore.setNotificationMessage("Skontrolujte povinné polia", null, "error")
		}
	}

	const [form] = useState(new MobxReactForm(fields.load(), {plugins: {dvr: dvr(validatorjs)}, hooks}))

	useEffect(() => {
		CallRoundDialogStore.formRef = form
	}, [])

	return (
		<Dialog
			id="callRoundDialog"
			open={CallRoundDialogStore.isOpen}
			onClose={() => CallRoundDialogStore.close()}
			className="xs-base-dialog dialog-newpatient"
			classes={{
				paper: "xs-paper-dialog xs-width-paper-900"
			}}
			disableBackdropClick={true}
			maxWidth="md"
		>
			<DialogTitle className="xs-info">
				<div className="xs-header">
					<div
						style={{position: "absolute", right: 24, top: 16, cursor: "pointer"}}
						onClick={() => {
							CallRoundDialogStore.close()
						}}
					>
						<i className="fal fa-times fa-lg" />
					</div>
					<div className="xs-header-title">Pridať kolo výzvy (Región & Skupinu zariadenia)</div>
				</div>
			</DialogTitle>
			<DialogContent style={{minWidth: 760}}>
				<Grid item container xs={12} className="mb-2" spacing={2}>
					<Grid item xs={12} md={6}>
						<XsSearchSelect field={form.$("region")} items={GlobalStore.CL["regionType"]} />
					</Grid>
					<Grid item xs={12} md={6}>
						<XsSearchSelect field={form.$("group")} items={GlobalStore.CL["deviceGroup"]} />
					</Grid>
					<Grid item xs={12} md={6}>
						<XsInput field={form.$("allocation")} />
					</Grid>
					<Grid item xs={12} md={6}>
						<XsDateTimePicker field={form.$("validity_from")} />
					</Grid>
					<Grid item xs={12} md={6}>
						<XsDateTimePicker field={form.$("start")} />
					</Grid>
					<Grid item xs={12} md={6}>
						<XsDateTimePicker field={form.$("validity_to")} />
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Grid item container justify="flex-end" className="mr-2 mb-2">
					<Grid item>
						<XsButton
							className="xs-success"
							icon={<i className="fal fa-check" />}
							text="Uložiť"
							onClick={(e) => form.onSubmit(e)}
						/>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	)
}

export default injectIntl(observer(CallRoundDialog))

