"use strict"
import React from "react"
import {observer} from "mobx-react"
import api from "../../global/actions/api"
import {Container, Grid, Typography, Hidden, Link} from "@material-ui/core"
import ReactTooltip from "react-tooltip"
import {FormattedMessage, injectIntl} from "react-intl"
import XsInput from "../../global/ui/xsInput/xsInput"
import XsAutocomplete from "../../global/ui/xsInput/xsAutocomplete"
import XsNumberInput from "../../global/ui/xsInput/xsNumberInput"
import AddIcon from "@material-ui/icons/Add"
import XsTextArea from "../../global/ui/xsTextArea/xsTextArea"
import GlobalStore from "../../global/store/GlobalStore"
import XsConfirmationDialog from "../../global/ui/xsDialog/xsConfirmationDialog"
import XsButton from "../../global/ui/xsButton/xsButton"
import XsChipForm from "../../global/ui/xsChipForm/xsChipForm"
import XsChip from "../../global/ui/xsChip/xsChip"
import XsIconButton from "../../global/ui/xsButton/xsIconButton"
import InfoStore from "../../global/store/InfoStore"
import RequestFormStore from "./RequestFormStore"
import RouterStore from "../../global/store/RouterStore"
import XsSearchSelect from "../../global/ui/xsSearchSelect/xsSearchSelect"
import RequestPrintStore from "./RequestPrintStore"
import XsCheckbox from "../../global/ui/xsCheckbox/xsCheckbox"
import renderHTML from "react-render-html"
import {isIBANValid, isPhoneNumberValid} from "../../global/helpers/functions"
import Dropzone from "react-dropzone"

function IP_PC_124(props) {
	const {form, editingMode, requiredAttachemnts} = props
	const handleCorespondingAddressValidation = () => {
		if (form.$("companyAddressSameNo").value == true) {
			form.$("companyCorrespondenceRegion").set("rules", "required")
			form.$("companyCorrespondenceStreet").set("rules", "required")
			form.$("companyCorrespondenceCity").set("rules", "required")
			form.$("companyCorrespondenceStreetNumber").set("rules", "required")
			form.$("companyCorrespondencePSC").set("rules", "required|between:5,5")
		} else {
			form.$("companyCorrespondenceRegion").set("value", "")
			form.$("companyCorrespondenceStreet").set("value", "")
			form.$("companyCorrespondenceCity").set("value", "")
			form.$("companyCorrespondenceStreetNumber").set("value", "")
			form.$("companyCorrespondencePSC").set("value", "")

			form.$("companyCorrespondenceRegion").set("rules", "")
			form.$("companyCorrespondenceStreet").set("rules", "")
			form.$("companyCorrespondenceCity").set("rules", "")
			form.$("companyCorrespondenceStreetNumber").set("rules", "")
			form.$("companyCorrespondencePSC").set("rules", "")
		}
	}

	const isFormDisabled = () => {
		return Boolean(
			!editingMode || GlobalStore.isRegistrationClose()
			// ((form.$("status").value != "inp" && isNotEmpty(form.$("status").value)) || GlobalStore.isRegistrationClose()) &&
		)
	}

	const handleDropzonePreview = (attachmentIndex, dropzoneIndex) => {
		var FileSaver = require("file-saver") // eslint-disable-line no-undef
		FileSaver.saveAs(
			RequestFormStore[`dropedFiles${dropzoneIndex}`][attachmentIndex],
			RequestFormStore[`dropedFiles${dropzoneIndex}`][attachmentIndex].name
			// isNotEmpty(form.$("zoIPNumber").value)
			// 	? form.$("zoIPNumber").value.replace("ZoIP", "CP")
			// 	:
		)
	}

	const handleDrop = (files, index, length) => {
		for (let fileIndex in files) {
			let file = files[fileIndex]
			if (file.size > 20971520) {
				GlobalStore.setNotificationMessage("Size limit exceeded!", null, "error")
				continue
			}
			if (RequestFormStore[`dropedFiles${index}`].length >= length) {
				GlobalStore.setNotificationMessage("Maximum number of files exceeded!", null, "error")
				continue
			}

			if (index != "Other") {
				if (index == 0) {
					file.databaseType = "priceComparison"
				}
			} else {
				file.databaseType = "General"
			}
			RequestFormStore[`dropedFiles${index}`].push(file)
		}
	}

	const handleDropzoneDelete = (attachmentIndex, dropzoneIndex) => {
		if (isSafe(RequestFormStore[`dropedFiles${dropzoneIndex}`][attachmentIndex]._id)) {
			RequestFormStore.deleteLoadedAttachemnt(
				RequestFormStore[`dropedFiles${dropzoneIndex}`][attachmentIndex]._id,
				dropzoneIndex,
				attachmentIndex
			)
		} else {
			RequestFormStore[`dropedFiles${dropzoneIndex}`].splice(attachmentIndex, 1)
		}
	}

	return (
		<React.Fragment>
			<Container maxWidth="lg">
				<Container maxWidth="lg" className="request_form_fields">
					<Grid container spacing={2} className="pt-5">
						<Grid item xs={12}>
							<Typography variant="h4" align="center">
								Žiadosť o poskytnutie pomoci prostredníctvom Inovačnej poukážky (ŽoIP)
							</Typography>
						</Grid>
						{/* <Grid item xs={12} className="mb-8">
							<Typography variant="h6" align="center">
								{form.$("zoIPNumber").value}
							</Typography>
						</Grid> */}
						<Grid item xs={12}>
							<Typography variant="h5">Identifikácia oprávneného Žiadateľa</Typography>
						</Grid>
						<Grid item xs={12} md={6} lg={3} data-tip data-for="companyName">
							<XsInput field={form.$("companyName")} disabled={true} />
							<ReactTooltip className="custom-tooltip-line-height" id="companyName">
								Obchodné meno spoločnosti už nie je možné zmeniť. Prípadná oprava bude vykonaná administrátorom SIEA pri
								posúdení podmienok ŽoIP.
							</ReactTooltip>
						</Grid>
						<Grid item xs={12} md={6} lg={3} data-tip data-for="ico">
							<XsNumberInput field={form.$("ico")} step={"1"} min={"1"} disabled={true} />
							<ReactTooltip className="custom-tooltip-line-height" id="ico">
								IČO spoločnosti už nie je možné zmeniť. Prípadná oprava bude vykonaná administrátorom SIEA pri posúdení
								podmienok ŽoIP.
							</ReactTooltip>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsNumberInput field={form.$("dic")} step={"1"} min={"1"} disabled={isFormDisabled()} />
						</Grid>
						<Grid item xs={12} md={6} lg={3} data-tip data-for="icdph">
							<XsInput field={form.$("icdph")} disabled={isFormDisabled()} />
							<ReactTooltip className="custom-tooltip-line-height" id="icdph">
								V prípade, že máte IČ DPH, je potrebné ho uviesť. V prípade, že IČ DPH nemáte, je potrebné uviesť do
								bunky IČ DPH nulu.
							</ReactTooltip>
						</Grid>

						<Grid item xs={12} md={6} lg={3}>
							<XsSearchSelect
								field={form.$("companyDesc")}
								disabled={isFormDisabled()}
								items={GlobalStore.CL["companyType"]}
							/>
						</Grid>

						<Grid item xs={12} md={6} lg={3} /*data-tip data-for="companyLegalForm"*/>
							<XsSearchSelect
								field={form.$("companyLegalForm")}
								disabled={isFormDisabled()}
								items={GlobalStore.CL["legalForm"]}
								specialTooltip={
									"Ak ste vybrali možnosť Iný typ právnej formy, doplňte prosím informáciu o inom type v časti žiadosti Predmet ŽoIP - Opis inovačného projektu v poli Doplňujúce údaje."
								}
							/>
							{/* <ReactTooltip className="custom-tooltip-line-height" id="companyLegalForm">
								Ak ste vybrali možnosť Iný typ právnej formy, doplňte prosím informáciu o inom type v časti žiadosti
								Predmet ŽoIP - Opis inovačného projektu v polí Doplňujúce údaje.
							</ReactTooltip> */}
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsSearchSelect
								field={form.$("companySize")}
								disabled={isFormDisabled()}
								items={GlobalStore.CL["companySize"]}
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={6} data-tip data-for="companyIBANtooltip">
							<XsInput field={form.$("companyIBAN")} disabled={isFormDisabled()} regex={(val) => isIBANValid(val)} />
							<ReactTooltip className="custom-tooltip-line-height" id="companyIBANtooltip">
								Uveďte IBAN Žiadateľa zhodný s číslom účtu uvedenom v Prílohe č.3. - Sken zmluvy o podnikateľskom účte.
							</ReactTooltip>
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Typography variant="h6">Adresa sídla podnikania (v súlade s OR SR alebo ŽR SR)</Typography>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsSearchSelect
								field={form.$("companyRegion")}
								items={
									//Okrem BB to ma vlastnu ziadost
									form.$("callCode").value == "IP_SK_121" && isSafe(GlobalStore.CL["region"])
										? GlobalStore.CL["region"].filter((row) => row.code != "SK032")
										: GlobalStore.CL["region"]
								}
								required
								disabled={isFormDisabled()}
								onChange={() => {
									RequestFormStore.checkRegionAvailability(form)
									form.$("companyCity").value = ""
								}}
							/>
						</Grid>
						{/* <Grid item xs={12} md={6} lg={3}>
							<XsSearchSelect
								field={form.$("companyCounty")}
								items={
									isNotEmpty(form.$("companyRegion").value) && isSafe(GlobalStore.CL["addressCounty"])
										? GlobalStore.CL["addressCounty"].filter((row) => {
												return row.region == form.$("companyRegion").value
										  })
										: GlobalStore.CL["addressCounty"]
								}
								required
								disabled={isFormDisabled()}
								onChange={() => {
									form.$("companyCity").value = ""
								}}
							/>
						</Grid> */}
						<Grid item xs={12} md={6} lg={3}>
							<XsAutocomplete
								field={form.$("companyCity")}
								minLengthForSearch="1"
								api={api.loadEntityCity}
								inputRenderer={(obj) => obj.name_ext}
								saveValue={(obj) => obj.code}
								disabled={isFormDisabled()}
								addSpecialFilter={() => {
									// if (isNotEmpty(form.$("companyCounty").value)) {
									// 	return [
									// 		{
									// 			associated_column: "county",
									// 			predicate: "=",
									// 			values: [
									// 				{
									// 					id_value: form.$("companyCounty").value
									// 				}
									// 			]
									// 		}
									// 	]
									// }
								}}
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsInput field={form.$("companyStreet")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item xs={12} md={6} lg={3} data-tip data-for="companyStreetNumberTooltip">
							<XsInput field={form.$("companyStreetNumber")} disabled={isFormDisabled()} />
							<ReactTooltip className="custom-tooltip-line-height" id="companyStreetNumberTooltip">
								Uveďte číslo v tvare, ako ho máte zapísané v ORSR alebo ŽRSR.
							</ReactTooltip>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsNumberInput field={form.$("companyPSC")} min={"1"} step={"1"} disabled={isFormDisabled()} />
						</Grid>
						<Grid item xs={12}>
							<Typography variant="body2">Je adresa sídla zhodná s korešpondenčnou adresou?</Typography>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsCheckbox
								field={form.$("companyAddressSameYes")}
								disabled={isFormDisabled()}
								onChange={() => {
									form.$("companyAddressSameNo").value = false
									handleCorespondingAddressValidation()
								}}
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsCheckbox
								field={form.$("companyAddressSameNo")}
								disabled={isFormDisabled()}
								onChange={() => {
									form.$("companyAddressSameYes").value = false
									handleCorespondingAddressValidation()
								}}
							/>
						</Grid>
						{form.$("companyAddressSameNo").value == true && (
							<React.Fragment>
								<Grid item xs={12}></Grid>
								<Grid item xs={12} md={6} lg={3}>
									<XsSearchSelect
										field={form.$("companyCorrespondenceRegion")}
										items={GlobalStore.CL["region"]}
										// required
										disabled={isFormDisabled()}
										onChange={() => {
											form.$("companyCorrespondenceCity").value = ""
										}}
									/>
								</Grid>
								<Grid item xs={12} md={6} lg={3}>
									<XsAutocomplete
										field={form.$("companyCorrespondenceCity")}
										minLengthForSearch="1"
										api={api.loadEntityCity}
										inputRenderer={(obj) => obj.name_ext}
										saveValue={(obj) => obj.code}
										disabled={isFormDisabled()}
									/>
								</Grid>
								<Grid item xs={12} md={6} lg={3}>
									<XsInput field={form.$("companyCorrespondenceStreet")} disabled={isFormDisabled()} />
								</Grid>
								<Grid item xs={12} md={6} lg={3}>
									<XsInput field={form.$("companyCorrespondenceStreetNumber")} disabled={isFormDisabled()} />
								</Grid>
								<Grid item xs={12} md={6} lg={3}>
									<XsNumberInput
										field={form.$("companyCorrespondencePSC")}
										min={"1"}
										step={"1"}
										disabled={isFormDisabled()}
									/>
								</Grid>
							</React.Fragment>
						)}
						<Grid item xs={12}>
							<Typography variant="h5">Štatutárny orgán Žiadateľa / Fyzická osoba Žiadateľa</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="body2">
								V prípade viacerých členov štatutárneho orgánu ich môžete pridať pomocou kliknutia na znamienko +
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<XsChipForm
								field={form.$("applicantChipForm")}
								label={" "}
								configuration={{
									header: {
										chipsRenderer: (key, dataRow, clickHandler) => (
											<XsChip
												key={key}
												label={
													isSafe(dataRow["name"]) && isNotEmpty(dataRow["name"]["value"])
														? dataRow["name"]["value"]
														: ""
												}
												onClick={(e) => clickHandler(false, e, key)}
											/>
										),
										addButtonRenderer: (clickHandler) => (
											<XsIconButton className="xs-default xs-outline" icon={<AddIcon />} onClick={clickHandler} />
										)
									},
									columns: [
										{
											name: "name",
											label: "Titul, Meno, Priezvisko, Titul",
											width: 5,
											validationRule: "required",
											renderer: (field) => <XsInput field={field} disabled={isFormDisabled()} forceRequired />
										},
										{
											name: "isStatutar",
											label: "Zmluvu IP podpíše",
											width: 5,
											validationRule: "required",
											type: "checkbox",
											renderer: (field) => (
												<XsCheckbox
													tooltipId={
														"Uveďte všetkých členov štatutárneho orgánu v zmysle OR SR a ŽR SR a označte člena/členov štatutárneho orgánu, ktorí konajú a podpisujú v mene Žiadateľa a bude/budú podpisovať Zmluvu IP."
													}
													showTooltipAsText={true}
													field={field}
													disabled={isFormDisabled()}
												/>
											)
										}
									],
									options: {
										disabled: isFormDisabled(),
										disableFirstRowDelete: true,
										isFormMode: true,
										isModalMode: false,
										hideButtons: true,
										startOpen: true,
										maxLength: 5,
										hideHeader: true,
										onFormOpen: () => {},
										onFormClose: () => {},
										onFormSave: (vals) => {
											RequestFormStore.applicantChipForm = vals
										}
									},
									data: RequestFormStore.applicantChipForm
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="h5" data-tip data-for="contactPersonText">
								Kontaktná osoba Žiadateľa
							</Typography>
							<ReactTooltip className="custom-tooltip-line-height" id="contactPersonText">
								Osoba, ktorá zabezpečuje komunikáciu vo veci všetkých aktivít národného projektu ZIVSE 2, ktorých sa
								bude Váš podnik zúčastňovať.
							</ReactTooltip>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsInput field={form.$("contactPersonName")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item xs={12} md={6} lg={3} data-tip data-for="contactPersonPhoneTooltip">
							<XsInput
								field={form.$("contactPersonPhone")}
								disabled={isFormDisabled()}
								regex={(val) => isPhoneNumberValid(val)}
							/>
							<ReactTooltip className="custom-tooltip-line-height" id="contactPersonPhoneTooltip">
								Telefónne číslo zadávajte bez medzier.
							</ReactTooltip>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsInput field={form.$("contactPersonMail")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item xs={12}>
							<Typography variant="h5">Predmet ŽoIP - Opis inovačného projektu</Typography>
						</Grid>
						<Grid item xs={12} md={6} lg={4} data-tip data-for="projectName">
							<XsInput
								field={form.$("projectName")}
								disabled={isFormDisabled() || form.$("callCode").value == "IP_IPCEI_121"}
								maxLength={300}
							/>
						</Grid>
						<React.Fragment>
							<Hidden only={["sm", "xs"]}>
								<Grid item xs={1} md={6} lg={9}>
									<div></div>
								</Grid>
							</Hidden>
							<Grid item xs={12} md={6} lg={4}>
								<XsSearchSelect
									field={form.$("eligibleArea")}
									disabled={isFormDisabled()}
									items={
										isSafe(GlobalStore.CL["activityType"])
											? GlobalStore.CL["activityType"].filter((row) =>
													row.call_type ? row.call_type.code == "IP_PC_124" : false
											  )
											: []
									}
									onChange={(value) => {
										const type = GlobalStore.CL["activityType"].find((row) => row._id == value)
										form.$("eligibleAreaCode").value = type.code
									}}
								/>
							</Grid>
						</React.Fragment>
						<Grid item xs={12} md={12} lg={12}>
							<XsTextArea
								field={form.$("legitimateActivities")}
								disabled={isFormDisabled() || form.$("callCode").value == "IP_IPCEI_121"}
								maxLength={5000}
								onChange={() => {
									if (form.$("legitimateActivities").value.includes("<img src=")) {
										GlobalStore.setNotificationMessage("Vložte vstup bez obrázka!", null, "error")
										form.$("legitimateActivities").value = ""
									}
								}}
								tooltip={renderHTML(
									`Uveďte zameranie/aktivitu podľa zvolenej oblasti A/ Patenty alebo B/ Certifikácia, podľa Príručky bod č. 2.2.`
								)}
							/>
						</Grid>

						<Grid item xs={12} md={12} lg={12}>
							<XsTextArea
								field={form.$("projectDescAndTarget")}
								disabled={isFormDisabled() || form.$("callCode").value == "IP_IPCEI_121"}
								maxLength={5000}
								onChange={() => {
									if (form.$("projectDescAndTarget").value.includes("<img src=")) {
										GlobalStore.setNotificationMessage("Vložte vstup bez obrázka!", null, "error")
										form.$("projectDescAndTarget").value = ""
									}
								}}
								tooltip={renderHTML(
									`Povinne definujte cieľ projektu a aké budú jeho prínosy. Definujte súlad inovačného projektu s definovanou oblasťou a aktivitou/zameraním. Popíšte činnosti,<br/> prostredníctvom ktorých sa bude realizovať oprávnená oblasť a aktivita/zameranie v súlade s Príručkou PRE ŽIADATEĽA/PRIJÍMATEĽA, bod 2.2. Výstupom na<br/>základe poskytnutej Inovačnej poukážky musí byť pre oblasť: A) patenty - potvrdenie o podaní novej patentovej prihlášky, potvrdenie o výške poplatkov z príslušného úradu,<br/> pre oblasť B) certifikácia - protokol o skúške, správa o posúdení zhody/oznámenie o odmietnutí potvrdiť zhodu, certifikát zhody. Stručne popíšte riešenie a aký prínos má inovačný projekt pre Žiadateľa/zákazníka.`
								)}
							/>
						</Grid>
						<React.Fragment>
							<Grid item xs={12} md={12} lg={12}>
								<XsTextArea
									field={form.$("projectBasicParameters")}
									maxLength={5000}
									disabled={isFormDisabled()}
									onChange={() => {
										if (form.$("projectBasicParameters").value.includes("<img src=")) {
											GlobalStore.setNotificationMessage("Vložte vstup bez obrázka!", null, "error")
											form.$("projectBasicParameters").value = ""
										}
									}}
									tooltip={renderHTML(
										`Popíšte východiskovú situáciu vo vzťahu k predkladanému inovačnému projektu, resp. vstupom, ktoré ovplyvňujú realizáciu inovačného projektu, identifikované<br/> problémy a potreby.`
									)}
								/>
							</Grid>
							<Grid item xs={12} md={12} lg={12}>
								<XsTextArea
									field={form.$("projectImplementationMethod")}
									maxLength={5000}
									disabled={isFormDisabled()}
									onChange={() => {
										if (form.$("projectImplementationMethod").value.includes("<img src=")) {
											GlobalStore.setNotificationMessage("Vložte vstup bez obrázka!", null, "error")
											form.$("projectImplementationMethod").value = ""
										}
									}}
								/>
							</Grid>
							<Grid item xs={12} md={12} lg={12}>
								<XsTextArea
									field={form.$("projectRealizationPlace")}
									maxLength={500}
									disabled={isFormDisabled()}
									onChange={() => {
										if (form.$("projectRealizationPlace").value.includes("<img src=")) {
											GlobalStore.setNotificationMessage("Vložte vstup bez obrázka!", null, "error")
											form.$("projectRealizationPlace").value = ""
										}
									}}
									tooltip={renderHTML(
										`Povinne uveďte všetky miesta realizácie vrátane terénnych alebo laboratórnych podmienok. Pokiaľ Žiadateľ v tomto čase ešte <br /> nevie špecifikovať miesto realizácie, uvedie ako miesto realizácie - územie SR. V prípade, ak prebieha riešenie projektu<br /> s riešiteľom so sídlom mimo územia SR, Žiadateľ uvádza miesto realizácie napr. „v sídle riešiteľa“.`
									)}
								/>
							</Grid>
							<Grid item xs={12} md={12} lg={12}>
								<XsTextArea
									field={form.$("projectDeadlineCompletion")}
									maxLength={500}
									disabled={isFormDisabled()}
									onChange={() => {
										if (form.$("projectDeadlineCompletion").value.includes("<img src=")) {
											GlobalStore.setNotificationMessage("Vložte vstup bez obrázka!", null, "error")
											form.$("projectDeadlineCompletion").value = ""
										}
									}}
									tooltip={renderHTML(
										`Povinne definujte lehotu plnenia, ktorá nemôže prekročiť 12 mesiacov od vystavenia IP.`
									)}
								/>
							</Grid>
							<Grid item xs={12} md={12} lg={12}>
								<XsTextArea
									field={form.$("projectAdditionalInfo")}
									maxLength={5000}
									disabled={isFormDisabled()}
									onChange={() => {
										if (form.$("projectAdditionalInfo").value.includes("<img src=")) {
											GlobalStore.setNotificationMessage("Vložte vstup bez obrázka!", null, "error")
											form.$("projectAdditionalInfo").value = ""
										}
									}}
									tooltip={renderHTML(
										`Tu uveďte akékoľvek doplňujúce údaje, ktoré je potrebné uviesť vo vzťahu k potenciálnemu riešiteľovi inovačného projektu a k<br/> Opisu inovačného projektu pre zhotovenie cenovej ponuky, prípadne ostatné údaje potrebné k dostatočnosti popisu<br/> inovačného projektu. Ak sú informácie k inovačnému projektu zverejnené na webovom sídle Žiadateľa/riešiteľa, môžete ich<br/> uviesť priamo v tomto textovom poli, alebo pripojiť link.`
									)}
								/>
							</Grid>
							<Grid item xs={12} md={12} lg={12}>
								<XsTextArea
									field={form.$("oneCompanySubjectsIdentification")}
									maxLength={5000}
									disabled={isFormDisabled()}
									onChange={() => {
										if (form.$("oneCompanySubjectsIdentification").value.includes("<img src=")) {
											GlobalStore.setNotificationMessage("Vložte vstup bez obrázka!", null, "error")
											form.$("oneCompanySubjectsIdentification").value = ""
										}
									}}
									tooltip={renderHTML(`Jediný podnik  - zahŕňa všetky subjekty vykonávajúce hospodársku činnosť, medzi ktorými je aspoň jeden z týchto vzťahov:  <br/>a) jeden subjekt vykonávajúci hospodársku činnosť má väčšinu hlasovacích práv akcionárov alebo spoločníkov v inom subjekte vykonávajúcom hospodársku činnosť;<br/>b) jeden subjekt vykonávajúci hospodársku činnosť má právo vymenovať alebo odvolať väčšinu členov správneho, riadiaceho alebo dozorného orgánu iného subjektu<br/> vykonávajúceho hospodársku činnosť;<br/>  c) jeden subjekt vykonávajúci hospodársku činnosť má právo dominantným spôsobom ovplyvňovať iný subjekt vykonávajúci hospodársku činnosť na základe zmluvy,<br/> ktorú s daným subjektom vykonávajúcim hospodársku činnosť uzavrel, alebo na základe ustanovenia v zakladajúcom dokumente alebo stanovách spoločnosti;<br/>d) jeden subjekt vykonávajúci hospodársku činnosť, ktorý je akcionárom alebo spoločníkom iného subjektu vykonávajúceho hospodársku činnosť,<br/> má sám na základe zmluvy s inými akcionármi alebo spoločníkmi daného subjektu vykonávajúceho hospodársku činnosť pod kontrolou väčšinu hlasovacích práv akcionárov<br/> alebo spoločníkov v danom subjekte vykonávajúcom hospodársku činnosť.
Subjekty vykonávajúce hospodársku činnosť, medzi ktorými sú typy vzťahov uvedené<br/> v písm. a) až d) predchádzajúceho odseku prostredníctvom jedného alebo viacerých iných subjektov vykonávajúcich hospodársku činnosť, sa takisto považujú za jediný podnik.<br/> Definícia jediného podniku uvedená v Schéme pomoci de minimis na podporu zvyšovania výskumných a inovačných kapacít v podnikoch a rozvoja sieťovania aktérov <br/>inovačného ekosystému DM - 18/2024`)}
								/>
							</Grid>
							<Grid item container xs={12} md={12} lg={12}>
								{/* <XsTextArea
									field={form.$("otherSourcesSupport")}
									subTitle={`Poberáte alebo poberali ste pomoc de minimis v priebehu obdobia troch rokov predchádzajúcich deň predloženia ŽoIP? Ak áno, uveďte aké a v akej sume. (Prijatú pomoc de minimis Žiadateľ nájde v Informačnom systéme pre evidenciu a monitoring pomoci (IS SEMP), verejne dostupnom v elektronickej podobe na stránke https://semp.kti2dc.sk/ )`}
									maxLength={5000}
									disabled={isFormDisabled()}
									onChange={() => {
										if (form.$("otherSourcesSupport").value.includes("<img src=")) {
											GlobalStore.setNotificationMessage("Vložte vstup bez obrázka!", null, "error")
											form.$("otherSourcesSupport").value = ""
										}
									}}
									tooltip={renderHTML(`otherSourcesSupport`)}
								/> */}
								<Grid item xs={12}>
									<Typography variant="body2">
										<b>Pomoc z iných schém pomoci de minimis *</b>
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<Typography variant="body2">
										Žiada Žiadateľ v čase podania tejto ŽoIP o inú pomoc de minimis aj od iného poskytovateľa pomoci, v
										rámci iných schém pomoci de minimis?
									</Typography>
								</Grid>
								<Grid item md={2} xs={6}>
									<XsCheckbox
										field={form.$("otherSourcesSupportCheckboxYes")}
										disabled={isFormDisabled()}
										onChange={() => {
											form.$("otherSourcesSupportCheckboxYes").value = true
											form.$("otherSourcesSupportCheckboxNo").value = false
										}}
									/>
								</Grid>
								<Grid item md={2} xs={6}>
									<XsCheckbox
										field={form.$("otherSourcesSupportCheckboxNo")}
										disabled={isFormDisabled()}
										onChange={() => {
											form.$("otherSourcesSupportCheckboxYes").value = false
											form.$("otherSourcesSupportCheckboxNo").value = true
										}}
									/>
								</Grid>
							</Grid>
						</React.Fragment>
						<Grid item xs={12}>
							<Typography variant="h5">
								Identifikácia potenciálneho riešiteľa s najnižšou cenovou ponukou/oprávneného riešiteľa
							</Typography>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsInput field={form.$("potentialSolverName")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsNumberInput field={form.$("potentialSolverIco")} step={"1"} min={"1"} disabled={isFormDisabled()} />
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsNumberInput field={form.$("potentialSolverDic")} step={"1"} min={"1"} disabled={isFormDisabled()} />
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsInput field={form.$("potentialSolverIcdph")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item xs={12} md={6} lg={4}>
							<XsNumberInput
								field={form.$("potentialSolverCompletePrice")}
								step={"0.01"}
								min={"1"}
								disabled={isFormDisabled()}
								endText={"€"}
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={4} data-tip data-for="potentialSolverVoucherPriceTooltip">
							<XsNumberInput
								field={form.$("potentialSolverVoucherPrice")}
								step={"0.01"}
								min={"1"}
								disabled={isFormDisabled()}
								endText={"€"}
							/>
							<ReactTooltip className="custom-tooltip-line-height" id="potentialSolverVoucherPriceTooltip">
								Výška zadávanej sumy musí byť v rozmedzí: minimum 2 000,00 € a maximum 15 000,00 €.
							</ReactTooltip>
						</Grid>
						<Grid item xs={12} md={6} lg={4}>
							<XsNumberInput
								field={form.$("potentialSolverCofinancedPrice")}
								step={"0.01"}
								min={"1"}
								disabled={isFormDisabled()}
								endText={"€"}
							/>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="body2">
								1) V súlade s údajom vo formulári Vyhodnotenie cenového prieskumu <br />
								2) Výšku Inovačnej poukážky stanovuje Žiadateľ v súlade s Príručkou, kapitolou 2.4 Stanovenie výšky
								oprávnených výdavkov
								<br />
								3) Žiadateľ uvádza vlastné spolufinancovanie spolu s neoprávnenými výdavkami hradenými z vlastných
								zdrojov (ak relevantné)
							</Typography>
						</Grid>
						<Grid item xs={12} style={{marginTop: "50px"}}>
							<Typography variant="h5" align="center">
								Vyhlásenie Žiadateľa o splnení podmienok účasti v Schéme pomoci de minimis na podporu zvyšovania
								výskumných a inovačných kapacít v podnikoch a rozvoja sieťovania aktérov inovačného ekosystému{" "}
								<Typography variant="inherit" style={{whiteSpace: "nowrap"}}>
									DM - 18/2024
								</Typography>
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="h6" style={{fontWeight: "400"}}>
								1. Vyhlasujem, že ku dňu podania ŽoIP som fyzická alebo právnická osoba podľa § 2 ods. 2 zákona č.
								513/1991 Zb. Obchodný zákonník v znení neskorších predpisov, ktorá je registrovaná a vykonáva
								podnikateľskú činnosť v rámci Slovenskej republiky.
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<XsCheckbox isRequired field={form.$("confirmCheckbox1")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item xs={12}>
							<Typography variant="h6" style={{fontWeight: "400"}}>
								2. Vyhlasujem, že ku dňu podania ŽoIP mi nebola preplatená Inovačná poukážka v rámci iných Výziev NP
								ZIVSE2 a zároveň mi nebol preplatený maximálny počet Inovačných poukážok v rámci tejto Výzvy.
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<XsCheckbox isRequired field={form.$("confirmCheckbox2")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item xs={12}>
							<Typography variant="h6" style={{fontWeight: "400"}}>
								3. Vyhlasujem, že údaje uvedené v tejto ŽoIP a v Cenovom prieskume sú úplné, pravdivé a správne a boli
								poskytnuté slobodne a opis inovačného projektu, ktorý je súčasťou oslovenia potenciálnych riešiteľov na
								predloženie cenovej ponuky je totožný s opisom inovačného projektu v ŽoIP.
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<XsCheckbox isRequired field={form.$("confirmCheckbox3")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item xs={12}>
							<Typography variant="h6" style={{fontWeight: "400"}}>
								4. Vyhlasujem a rozumiem, že touto ŽoIP žiadam aj o poskytnutie komplexného online inovačného
								poradenstva nepriamou formou, ktorej pomoc nebude preplatená prostredníctvom Inovačnej poukážky a bude
								poskytnutá bezodplatne od SIEA.
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<XsCheckbox isRequired field={form.$("confirmCheckbox4")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item xs={12}>
							<Typography variant="h6" style={{fontWeight: "400"}}>
								5. Vyhlasujem, že spĺňam podmienky uvedené v bode K) Schémy pomoci de minimis na podporu zvyšovania
								výskumných a inovačných kapacít v podnikoch a rozvoja sieťovania aktérov Inovačného ekosystému.
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<XsCheckbox isRequired field={form.$("confirmCheckbox5")} disabled={isFormDisabled()} />
						</Grid>

						<Grid item xs={12}>
							<Typography variant="h6" style={{fontWeight: "400"}}>
								6. Vyhlasujem, že sa voči mne neuplatňuje vrátenie štátnej pomoci na základe rozhodnutia Európskej
								komisie, v ktorom bola táto štátna pomoc označená za neoprávnenú a nezlučiteľnú s vnútorným trhom (ak
								Žiadateľ patrí do skupiny podnikov, predloží toto vyhlásenie za všetkých členov skupiny podnikov, ktoré
								s ním tvoria Jediný podnik).
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<XsCheckbox isRequired field={form.$("confirmCheckbox6")} disabled={isFormDisabled()} />
						</Grid>

						<Grid item xs={12}>
							<Typography variant="h6" style={{fontWeight: "400"}}>
								7. Vyhlasujem, že som si vedomý právnych následkov v prípade, že sa preukáže, že čestné vyhlásenie nie
								je pravdivé.
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<XsCheckbox isRequired field={form.$("confirmCheckbox7")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item xs={12} style={{marginTop: "50px"}}>
							<Typography variant="h5">Súhlas na zasielanie marketingových informácií</Typography>
						</Grid>
						<Grid item xs={12}>
							Týmto udeľujem SIEA súhlas na zasielanie marketingových informácií a noviniek o službách a aktivitách SIEA
							a Národného projektu Zvýšenie inovačnej výkonnosti slovenskej ekonomiky 2 (ďalej len „NP ZIVSE2“), kód
							ITMS21+ : 401 101 A334, a to prostredníctvom elektronickej pošty na emailovú adresu uvedenú v tejto ŽoIP.
							Súhlas je udelený do 10 rokov od ukončenia doby udržateľnosti NP ZIVSE2 a je možné ho kedykoľvek odvolať
							zaslaním žiadosti na emailovú adresu&nbsp;
							<Link className="pointer" onClick={() => window.open("mailto:inovujme@siea.gov.sk ")}>
								inovujme@siea.gov.sk
							</Link>
							&nbsp;alebo zaslaním písomnej žiadosti na korešpondenčnú adresu SIEA.
						</Grid>

						<Grid item xs={12}>
							<XsCheckbox
								field={form.$("sentMarketingDataYes")}
								onChange={() => {
									form.$("sentMarketingDataYes").value = true
									form.$("sentMarketingDataNo").value = false
								}}
								disabled={isFormDisabled()}
							/>
						</Grid>
						<Grid item xs={12}>
							<XsCheckbox
								field={form.$("sentMarketingDataNo")}
								onChange={() => {
									form.$("sentMarketingDataYes").value = false
									form.$("sentMarketingDataNo").value = true
								}}
								disabled={isFormDisabled()}
							/>
						</Grid>
						<Grid item xs={12} style={{marginTop: "50px"}}>
							<Typography variant="h5">Povinná príloha k ŽoIP</Typography>
						</Grid>
						<Grid item container>
							{isSafe(RequestFormStore.attachmentTemplates) &&
								RequestFormStore.attachmentTemplates
									.filter((row) => requiredAttachemnts.includes(row))
									.map((template, dropzoneIndex) => {
										return (
											<Grid item container spacing={2} key={dropzoneIndex} data-tip data-for={`${template}Tooltip`}>
												<Grid item xs={12}>
													{template === "price" && (
														<Typography>
															Príloha č.1 - Cenový prieskum, ktorý musí obsahovať náležitosti podľa bodu 2.3 Príručky.
														</Typography>
													)}
													{template === "contractScan" && (
														<Typography>Príloha č.2 - Sken Zmluvy OR podpísanej oboma zmluvnými stranami.</Typography>
													)}
													{template === "bankAccountScan" && (
														<Typography>
															Príloha č.3 - Sken Zmluvy o zriadení podnikateľského účtu Žiadateľa uzatvorenej s bankou,
															alebo potvrdenie banky o zriadení podnikateľského účtu, na ktorý majú byť Žiadateľovi
															vyplácané prostriedky pomoci.
														</Typography>
													)}
												</Grid>
												<Grid item xs={12}>
													<Dropzone
														onDrop={(files) => {
															handleDrop(files, dropzoneIndex, 1)
														}}
														id="dropzone"
														disabled={isFormDisabled()}
														accept={".pdf"}
													>
														{isSafe(RequestFormStore[`dropedFiles${dropzoneIndex}`]) &&
														RequestFormStore[`dropedFiles${dropzoneIndex}`].length ? (
															RequestFormStore[`dropedFiles${dropzoneIndex}`].map((file, index) => (
																<div key={index} className={"dropedFile"}>
																	<i
																		className="fal fa-file icon"
																		onClick={(e) => {
																			e.stopPropagation()
																			handleDropzonePreview(index, dropzoneIndex)
																		}}
																	/>
																	<i
																		className="far fa-trash-alt deleteIcon"
																		onClick={(e) => {
																			e.stopPropagation()
																			if (!isFormDisabled()) {
																				GlobalStore.openConfirmationDialog("xsDeleteAttachmentFromDropzone", {
																					fileIndex: index,
																					dropzoneIndex: dropzoneIndex
																				})
																			}
																		}}
																	/>
																	<br />
																	<div
																		className={"mac_ellipsis"}
																		data-content-start={file.name.substr(0, Math.floor(file.name.length / 2))}
																		data-content-end={
																			"\u200E" + file.name.substr(Math.floor(file.name.length / 2)) + "\u200E"
																		}
																	/>
																</div>
															))
														) : (
															<div
																className={"dropzonePlaceholder"}
															>{`Nahrajte súbor jeho potiahnutím (drag and drop) alebo kliknite a vyberte z Vášho súborového systému`}</div>
														)}
													</Dropzone>
												</Grid>
											</Grid>
										)
									})}
						</Grid>
						<ReactTooltip className="custom-tooltip-line-height" id="priceTooltip">
							Na základe Príručky vložte prílohu: Cenový prieskum, výlučne vo formáte .pdf. Možné vložiť len jeden súbor
							PDF - obsahujúci všetky strany skenovaných dokumentov.
						</ReactTooltip>
						<ReactTooltip className="custom-tooltip-line-height" id="contractScanTooltip">
							Na základe Príručky vložte prílohu: Zmluva OR, výlučne vo formáte .pdf. Možné vložiť len jeden súbor PDF -
							obsahujúci všetky strany skenovaných dokumentov.
						</ReactTooltip>
						<ReactTooltip className="custom-tooltip-line-height" id="bankAccountScanTooltip">
							Na základe Príručky vložte prílohu: Zmluva o zriadení podnikateľského účtu, výlučne vo formáte .pdf. Možné
							vložiť len jeden súbor PDF - obsahujúci všetky strany skenovaných dokumentov.
						</ReactTooltip>
					</Grid>
				</Container>
				<Container maxWidth="lg" className="request_form_buttons" style={{marginTop: "15px"}}>
					<Grid item container spacing={1}>
						{/* {!editingMode && (
							<Grid item>
								<XsButton
									icon={<i className="fal fa-pencil"></i>}
									disabled={getUserAccessRights() != "FULL"}
									onClick={() => {
										delayedCallback(300, () => {
											setEditingMode(true)
										})
									}}
									text={"Upraviť žiadosť"}
								/>
							</Grid>
						)} */}
						{editingMode && (
							<React.Fragment>
								<Grid item>
									<XsButton
										icon={<i className="fal fa-save"></i>}
										onClick={() => {
											delayedCallback(300, () => {
												GlobalStore.openConfirmationDialog("xsSaveChanges")
											})
										}}
										disabled={
											// form.$("status").value == "sent" ||
											// GlobalStore.isRegistrationClose() ||
											// form.$("confirmCheckboxAllCorrect").value == false ||
											form.$("confirmCheckboxAddress").value == false ||
											form.$("confirmCheckbox12mothsPassed").value == false
											// (isIP_ZS_122 && form.$("confirmCheckboxConfirmConsulting").value == false)
										}
										text="Uložiť zmeny"
									/>
								</Grid>
							</React.Fragment>
						)}
						<Grid item>
							<XsButton
								icon={<i className="fal fa-save"></i>}
								onClick={(e) => {
									delayedCallback(300, () => {
										form.onSubmit(e)
									})
								}}
								disabled={
									form.$("status").value == "sent" ||
									GlobalStore.isRegistrationClose() ||
									// isRegistrationClose() ||
									form.$("confirmCheckbox1").value == false ||
									form.$("confirmCheckbox2").value == false ||
									form.$("confirmCheckbox3").value == false ||
									form.$("confirmCheckbox4").value == false ||
									form.$("confirmCheckbox5").value == false ||
									form.$("confirmCheckbox6").value == false ||
									form.$("confirmCheckbox7").value == false
									// form.$("confirmCheckboxAddress").value == false ||
									// form.$("confirmCheckbox12mothsPassed").value == false ||
									// ((isIP_ZS_122 || isIP_ZS2_123) && form.$("confirmCheckboxConfirmConsulting").value == false) ||
									// ((isIP_SK2_122 || isIP_SK3_122 || isIP_ZS2_123) && form.$("confirmCheckboxIP2SK5").value == false)
								}
								disableTextTransform
								text="ODOSLAŤ VYPRACOVANÚ ŽoIP"
							/>
						</Grid>
						<Grid item>
							<XsButton
								disabled={form.$("status").value != "sent"}
								icon={<i className="fal fa-print"></i>}
								onClick={() => {
									delayedCallback(300, () => {
										RequestPrintStore.print(form)
										InfoStore.open(
											"Povoľte, prosím, vyskakovacie okno pre túto stránku ak sa nezobrazí nove okno / záložka s vygenerovaným pdf.",
											<FormattedMessage id="Common.label.warning" />
										)
									})
								}}
								variant="outlined"
								text={"STIAHNUŤ PDF ŽIADOSTI"}
							/>
						</Grid>
						<Grid item>
							{/* <XsButton
								// disabled={form.$("status").value != "sent"}
								icon={<i className="fal fa-print"></i>}
								onClick={() => {
									delayedCallback(300, () => {
										RequestPrintStore.generateExcel(form)
										InfoStore.open(
											"Povoľte, prosím, vyskakovacie okno pre túto stránku ak sa nezobrazí nove okno / záložka s vygenerovaným pdf.",
											<FormattedMessage id="Common.label.warning" />
										)
									})
								}}
								variant="outlined"
								text={"STIAHNUŤ EXCEL ŽIADOSTI"}
							/> */}
						</Grid>
						<Grid item>
							<XsButton
								icon={<i className="fal fa-times"></i>}
								onClick={() => {
									delayedCallback(300, () => {
										if (RequestFormStore.isSameFormValues()) {
											form.reset()
											RouterStore.push("/requests")
										} else {
											GlobalStore.openConfirmationDialog("xsCloseRequestForm")
										}
									})
								}}
								color="secondary"
								text={editingMode ? "ZATVORIŤ A ZRUŠIŤ ZMENY" : "Zatvoriť"}
							/>
						</Grid>
					</Grid>
				</Container>
			</Container>
			<XsConfirmationDialog
				name="xsDeleteAttachmentFromDropzone"
				title={"Pozor"}
				text={"Naozaj chcete odstrániť prílohu?"}
				onConfirmation={() =>
					handleDropzoneDelete(
						GlobalStore.confirmationDialogParams.fileIndex,
						GlobalStore.confirmationDialogParams.dropzoneIndex
					)
				}
			/>
			<XsConfirmationDialog
				name="xsCloseRequestForm"
				text="Naozaj chcete opustiť formulár a zahodť zmeny?"
				confirmBtn={{
					icon: <i className="fal fa-check" />,
					text: "Common.btn.yes"
				}}
				cancelBtn={{
					icon: <i className="fal fa-times" />,
					text: "Common.btn.no"
				}}
				onConfirmation={() => {
					form.reset()
					RouterStore.push("/requests")
				}}
			/>

			<XsConfirmationDialog
				name="xsSaveChanges"
				text={`Prajete si uloziť zmeny?`}
				confirmBtn={{
					icon: <i className="fal fa-check" />,
					text: "Common.btn.yes"
				}}
				cancelBtn={{
					icon: <i className="fal fa-times" />,
					text: "Common.btn.no"
				}}
				onConfirmation={() => {
					form.submit()
				}}
			/>
		</React.Fragment>
	)
}

export default injectIntl(observer(IP_PC_124))

