"use strict"

import React, {useEffect, useState} from "react"
import {observer} from "mobx-react"
import {injectIntl} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import dvr from "mobx-react-form/lib/validators/DVR"

import {Grid, Paper, Container, Typography, Link, Box} from "@material-ui/core"

import XsInput from "../../global/ui/xsInput/xsInput"
import XsButton from "../../global/ui/xsButton/xsButton"

import {isPasswordValid} from "../../global/helpers/functions"

import GlobalStore from "../../global/store/GlobalStore"
import RouterStore from "../../global/store/RouterStore"
import LoginStore from "./loginStore"

function RegisterContainer() {
	const hooks = {
		onSubmit(form) {
			const {password, conf_psw} = form.values()

			if (isNotEmpty(password) && password != conf_psw) {
				GlobalStore.setNotificationMessage("Heslo a potvrdenie hesla sa musia zhodovať!", "", "warning")
				form.$("password").invalidate()
			} else if (!isPasswordValid(password) || !isPasswordValid(conf_psw)) {
				GlobalStore.setNotificationMessage(
					"Heslo musí obsahovať aspoň jedno veľké písmeno, jedno malé písmeno a jedno číslo. Minimálna dĺžka hesla je 8 znakov.",
					"",
					"warning"
				)
				form.$("password").invalidate()
			}
		},
		onSuccess(form) {
			LoginStore.registrationFinish(form)
		},
		onError() {
			// GlobalStore.setNotificationMessage("Skontrolujte povinné polia!", "", "warning")
		}
	}

	const [form] = useState(
		new MobxReactForm(
			{
				fields: {
					password: {
						label: "Nové heslo"
					},
					conf_psw: {
						label: "Potvrdenie hesla"
					}
				}
			},
			{plugins: {dvr: dvr(validatorjs)}, hooks}
		)
	)

	useEffect(() => {
		LoginStore.token = new URLSearchParams(RouterStore.location.search).get("token")
		LoginStore.email = new URLSearchParams(RouterStore.location.search).get("email")
	}, [])

	return (
		<React.Fragment>
			<div className="main_header">
				<Container style={{maxWidth: "90%"}}>
					<Grid container alignItems="center" justify="space-between" className="pt-2 pb-2">
						<Grid item>
							<div>
								<img
									src="/public/images/logo_siea_blue.png"
									style={{paddingBottom: "22px", paddingRight: "16px"}}
									className="pointer"
									onClick={() => RouterStore.push("/login")}
								/>
								<img
									src="/public/images/logo_inovujme_backoffice.png"
									style={{width: "100px", height: "100px"}}
									className="pointer"
									onClick={() => RouterStore.push("/login")}
								/>
							</div>
						</Grid>
						<Grid item className="header_gradient">
							<Typography variant="h3">Registrácia</Typography>
						</Grid>
						<Grid item style={{width: 368}}></Grid>
					</Grid>
				</Container>
			</div>
			<Container maxWidth="lg">
				<Grid container justify="center">
					<Grid item style={{maxWidth: "400px", width: "400px"}}>
						<Grid container direction="column" justify="center" style={{height: "65vh"}}>
							<Paper variant="elevation" elevation={2} style={{backgroundColor: "transparent"}}>
								<Box p={2}>
									<Grid item container direction="column" spacing={2}>
										<Grid item>
											<Typography variant="body2" style={{paddingBottom: "8px"}}>
												{/* To successfully complete the registration, fill in the New password and Confirm password fields.
												You will then use this password to log in to the portal. You will use your email address{" "}
												{LoginStore.email} that you entered during registration as your login name. Password must
												contain at least one uppercase letter, one lowercase letter and one number. Password must be at
												least 8 characters long. */}
												Pre úspešné dokončenie registrácie vyplňte položku Nové heslo a Potvrdenie hesla. Toto heslo
												budete následne používať na prihlásenie do portálu. Ako vaše prihlasovacie meno budete používať
												vašu emailovú adresu {LoginStore.email}, ktorú ste zadali pri registrácii.
											</Typography>
											<Typography variant="body2">
												Heslo musí spĺňať nasledovné kritériá:
												<ul>
													<li>musí obsahovať aspoň jedno VEĽKÉ písmeno</li>
													<li>musí obsahovať aspoň jedno malé písmeno</li>
													<li>musí obsahovať aspoň jedno číslo</li>
													<li>musí obsahovať aspoň jeden špeciálny znak</li>
													<li>dĺžka hesla musí byť minimálne 8 znakov a maximálne 50 znakov</li>
												</ul>
											</Typography>
										</Grid>

										<Grid item>
											<XsInput
												regex={(val) => isPasswordValid(val)}
												type="password"
												field={form.$("password")}
												autoFocus={true}
												submitAction={(e) => form.onSubmit(e)}
											/>
										</Grid>
										<Grid item>
											<XsInput
												regex={(val) => isPasswordValid(val)}
												type="password"
												field={form.$("conf_psw")}
												submitAction={(e) => form.onSubmit(e)}
											/>
										</Grid>
										<Grid item>
											<XsButton text={"Registrovať"} onClick={form.onSubmit} />
										</Grid>
										<Grid item>
											<Link
												variant="body2"
												className="pointer"
												onClick={() => {
													RouterStore.push("./login")
													LoginStore.screen = LoginStore.screens.LOGIN
												}}
											>
												Prihlásiť sa
											</Link>
										</Grid>
									</Grid>
								</Box>
							</Paper>
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</React.Fragment>
	)
}

export default injectIntl(observer(RegisterContainer))

