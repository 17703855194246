"use strict"

import React, {useState} from "react"
import {observer} from "mobx-react"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import dvr from "mobx-react-form/lib/validators/DVR"
import fields from "./registrastionsFields"
import moment from "moment"
import {injectIntl} from "react-intl"
import {Box, Grid, Typography} from "@material-ui/core"
import XsTable from "../../global/ui/xsTable/xsTable"
import SettingsStore from "./settingsStore"
import GlobalStore from "../../global/store/GlobalStore"
import XsInput from "../../global/ui/xsInput/xsInput"
import XsButton from "../../global/ui/xsButton/xsButton"
import XsConfirmationDialog from "../../global/ui/xsDialog/xsConfirmationDialog"

const columns = [
	{
		Header: "Dátum registrácie",
		width: 70,
		accessor: "created_at",
		Cell: (props) => <div>{isNotEmpty(props.value) ? moment(props.value).format("DD.MM.YYYY HH:mm") : ""}</div>
	},
	{
		Header: "IČO",
		width: 70,
		accessor: "company_identifier"
	},
	{
		Header: "Obchodné meno",
		width: 100,
		accessor: "company_name"
	},
	{
		Header: "Prihlasovacie meno / email",
		width: 120,
		accessor: "login"
	},
	{
		Header: "Potvrdená",
		width: 80,
		accessor: "is_confirmed",
		Cell: (props) => <div>{props.value ? "áno" : "nie"}</div>
	},
	{
		Header: "Akcie",
		width: 30,
		accessor: "_id",
		Cell: (props) => (
			<i
				className="far fa-trash-alt fa-lg redDanger"
				onClick={(e) => {
					e.stopPropagation()
					GlobalStore.openConfirmationDialog("xsDeleteRegistrationWithoutRequest", {
						requestId: props.value
					})
				}}
			/>
		)
	}
]

function RegistrationsWithoutRequest() {
	const hooks = {
		onSubmit() {},
		onSuccess(form) {
			SettingsStore.getRegistrationsWithoutRequest(form)
		},
		onError() {
			GlobalStore.setNotificationMessage("Skontrolujte povinné polia", null, "error")
		}
	}

	const [form] = useState(new MobxReactForm(fields.load(), {plugins: {dvr: dvr(validatorjs)}, hooks}))

	return (
		<Grid container className="pt-8">
			<Grid item container xs={12}>
				<Grid container alignItems="center" style={{gap: 8}}>
					<Grid item>
						<Typography variant="caption" style={{fontWeight: "bold"}}>
							Vyhľadať v existujúcich záznamoch:
						</Typography>
					</Grid>
					<Grid item style={{flex: 1}}>
						<div style={{height: 1, backgroundColor: "#CED7D9", width: "100%"}}></div>
					</Grid>
				</Grid>
				<Grid container alignItems="center" style={{gap: 8}} className="mt-3 mb-3">
					<Grid item xs={2}>
						<XsInput field={form.$("search")} />
					</Grid>
					<Grid item xs={2}>
						<XsInput field={form.$("login")} />
					</Grid>
					<Grid item container xs justify="flex-end" spacing={2}>
						<Grid item>
							<XsButton
								rect
								className="xs-default xs-outline fa-pull-right"
								text="VYHĽADAŤ"
								onClick={form.onSubmit}
								icon={<i className="far fa-search fa-lg xs-greyDefault"></i>}
							/>
						</Grid>
						<Grid item>
							<XsButton
								rect
								className="xs-default xs-outline fa-pull-right mr-1"
								variant="outlined"
								color="primary"
								text="VYMAZAŤ FILTER"
								onClick={(e) => {
									form.reset()
									form.onSubmit(e)
								}}
								icon={<i className="far fa-times fa-lg xs-greyDefault"></i>}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Box pt={1}>
					<XsTable
						columns={columns}
						data={SettingsStore.data}
						// onRowClick={(row) => RouterStore.push(`/request?id=${row.request_id}`)}
					/>
				</Box>
			</Grid>
			<XsConfirmationDialog
				name="xsDeleteRegistrationWithoutRequest"
				title={"Odstránenie registrácie"}
				text={"Naozaj chcete odstrániť registráciu?"}
				onConfirmation={() =>
					SettingsStore.deleteRegistrationRequest(GlobalStore.confirmationDialogParams.requestId, form)
				}
			/>
		</Grid>
	)
}

export default injectIntl(observer(RegistrationsWithoutRequest))

