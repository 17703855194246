export default {
	load() {
		return {
			fields: {
				search: {
					label: "Obchodné meno / IČO"
				},
				login: {
					label: "Prihlasovacie meno / email"
				}
			}
		}
	}
}

