"use strict"

import {observable, action} from "mobx"

import {getFilters} from "../../global/helpers/api"
import api from "../../global/actions/api"

import GlobalStore from "../../global/store/GlobalStore"

class SettingsStore {
	@observable data = []
	@observable settingsData = []
	@observable tabValue = "settings"

	@action getRegistrationsWithoutRequest(form) {
		const values = isNotEmpty(form) ? form.values() : {}

		GlobalStore.loading(true, "Načítam...")

		let req = getFilters([])

		if (isNotEmpty(values.search)) {
			req.filters.push({
				associated_column: "company_search",
				predicate: "=",
				values: [
					{
						id_value: values.search
					}
				]
			})
		}

		if (isNotEmpty(values.login)) {
			req.filters.push({
				associated_column: "login",
				predicate: "=",
				values: [
					{
						id_value: values.login
					}
				]
			})
		}

		api
			.getRegistrationsWithoutRequest(req)
			.call()
			.then((res) => {
				if (isSafe(res) && isSafe(res.rows)) {
					this.data = res.rows
				} else {
					this.data = []
				}

				GlobalStore.loading(false)
			})
	}

	@action deleteRegistrationRequest(registrationId, form) {
		api
			.deleteRegistration(registrationId)
			.call()
			.then(() => {
				GlobalStore.setNotificationMessage("Registrácia úspešne odstránená")
				this.getRegistrationsWithoutRequest(form)
			})
	}
}

var singleton = new SettingsStore()
export default singleton

