import config from "../config/settings"
// import {onApiError} from "./functions"
import {getUser, logout} from "./actions"
import RouterStore from "../store/RouterStore"
import ErrorStore from "../store/ErrorStore"
import GlobalStore from "../store/GlobalStore"

export function ApiCall(options, method, body, hideError = false) {
	if (options && typeof options === "object") {
		this.path = options.path
		this.method = options.method || "GET"
		this.body = options.body
		this.headers = options.headers
		this.json = options.json
		this.customURL = options.customURL
		this.transform = options.transform === undefined ? true : options.transform
		this.includeToken = options.includeToken === undefined ? true : options.includeToken
	} else {
		this.path = options
		this.method = method || "GET"
		this.body = body
		this.transform = true
		this.includeToken = true
		this.hideError = hideError
	}
}

ApiCall.prototype.getURL = function() {
	return `${config.API_BASE_URL}${this.path}`
}
ApiCall.prototype.call = function(getStatusCode, requestId, getRequestId, timeout) {
	if (isSafe(requestId)) {
		if (isSafe(this.headers)) {
			this.headers = Object.assign(this.headers, {REQUESTID: requestId})
		} else {
			this.headers = {REQUESTID: requestId}
		}
	}
	return api(
		this.path,
		{
			method: this.method,
			body: this.body,
			headers: this.headers,
			json: this.json === false ? undefined : !!this.body,
			transform: this.transform || true,
			includeToken: this.includeToken
		},
		this.transform,
		this.customURL,
		getStatusCode,
		getRequestId,
		timeout,
		this.hideError
	)
}
ApiCall.prototype.getPath = function() {
	return this.path
}

function ApiError(status, statusText, response, additionalInfoForError, hideError = false) {
	if (isSafe(response) && isSafe(response.errors) && response.errors.length > 0 && response.errors[0].code == -1207) {
		if (RouterStore.location.pathname == "/" || RouterStore.location.pathname == "/login") {
			ErrorStore.incorectLoginOrPassword = true
		}
		RouterStore.push("/login")
	} else {
		GlobalStore.loading(false)
		ErrorStore.incorectLoginOrPassword = false
		if (!hideError) {
			ErrorStore.status = status
			ErrorStore.statusText = statusText
			ErrorStore.response = response
			ErrorStore.additionalInfo = additionalInfoForError
			ErrorStore.open()
		}
	}
}

const createDefaultHeaders = (includeToken) => {
	const user = getUser()
	if (includeToken && user) {
		return {
			Authorization: `SIEA ${user.sieatoken || ""}`
		}
	} else {
		return {}
	}
}

const adjustOptions = (options) => {
	if (!options) {
		options = {}
	}

	options.credentials = "include"

	if (!options.headers) {
		options.headers = {}
	}

	// if (typeof options.headers.then === "function") {
	//   options.headers = options.headers;
	// }

	const headers = createDefaultHeaders(options.includeToken)
	if (options.json === true) {
		if (typeof options.body === "object") {
			options.body = JSON.stringify(options.body)
		}
		headers["Content-Type"] = "application/json"
	}

	Object.assign(options.headers, headers)

	return options
}

const checkStatus = (response, additionalInfoForError, hideError = false) => {
	if (response.status >= 200 && response.status < 300) {
		return response
	} else {
		response
			.json()
			.catch(() => {
				throw new ApiError(response.status, response.statusText, null, additionalInfoForError, hideError)
			})
			.then((json) => {
				// onApiError(response.status, response.statusText, json, additionalInfoForError, hideError)
				throw new ApiError(response.status, response.statusText, json, additionalInfoForError, hideError)
			})
	}
}

const parseJSON = (response, getRequestId) => {
	let contentType = response.headers.get("Content-Type")
	contentType = contentType && contentType.split(";")[0]
	if (typeof getRequestId === "function") {
		getRequestId(response.headers.get("ixs-requestid"))
	}
	if (!contentType || contentType === "application/json") {
		return response.json().catch(() => response)
	} else {
		return response
	}
}

const handleApiCatch = (response, additionalInfoForError, hideError = false) => {
	if (response == "TypeError: Failed to fetch") {
		throw new ApiError("", "", {errors: [{description: "chyba"}]}, additionalInfoForError, hideError)
	} else if (response == "Error: Client timeout") {
		throw new ApiError("", "", {errors: [{description: "chyba timeout"}]}, additionalInfoForError, hideError)
	} else {
		throw new ApiError("", "", {errors: [{description: "chyba"}]}, additionalInfoForError, hideError)
	}
}

const timeoutPromise = (ms, promise) => {
	return new Promise((resolve, reject) => {
		let timeoutId = setTimeout(() => {
			timeoutId = undefined
			reject(new Error("Client timeout"))
		}, ms)
		promise.then(
			(res) => {
				if (timeoutId) {
					clearTimeout(timeoutId)
					resolve(res)
				}
			},
			(err) => {
				if (timeoutId) {
					clearTimeout(timeoutId)
					reject(err)
				}
			}
		)
	})
}

let startExpireToken = null

const setExpireToken = (headers) => {
	const autHeader = headers.get("AUTHORIZATION")
	if (isSafe(autHeader) && autHeader.includes("expires=")) {
		const splitAutHeader = headers.get("AUTHORIZATION").split("expires=")

		if (isSafe(splitAutHeader) && splitAutHeader.length > 1) {
			const expire = splitAutHeader[1].replace(";", "").trim()
			const dateObject = new Date(expire)
			const expireToken = dateObject.getTime()

			if (isNotEmpty(expireToken) && isFinite(expireToken)) {
				clearInterval(startExpireToken)

				startExpireToken = setInterval(function() {
					// Get today's date and time
					const now = new Date().getTime()
					// Find the distance between now and the count down date
					const distance = expireToken - now

					// Time calculations for days, hours, minutes and seconds
					const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
					const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
					const seconds = Math.floor((distance % (1000 * 60)) / 1000)
					// If the count down is finished, write some text

					if (hours < 1 && minutes < 1 && seconds <= 45) {
						GlobalStore.countdownExpireToken = seconds - 15

						if (seconds <= 15) {
							clearInterval(startExpireToken)
							GlobalStore.closeConfirmationDialog()
						}

						if (
							RouterStore.location.pathname != "/" &&
							RouterStore.location.pathname != "/login" &&
							RouterStore.location.pathname != "/registerFinish" &&
							RouterStore.location.pathname != "/passwordResetFinish"
						) {
							GlobalStore.openConfirmationDialog("xsWarningTokenExpired")

							if (seconds <= 15) {
								logout()
							}
						}
					}
				}, 1000)
			}
		}
	}
}

const api = (
	path,
	options,
	transform = true,
	customURL = null,
	getStatusCode,
	getRequestId,
	timeout,
	hideError = false
) => {
	const fetchPath = customURL || config.API_BASE_URL + path
	const fetchPathWithTimestamp = `${fetchPath}${fetchPath.includes("?") ? "&" : "?"}nctsmp=${Date.now()}`
	const additionalInfoForError = {path, options}

	return timeoutPromise(timeout || config.API_CLIENT_TIMEOUT, fetch(fetchPathWithTimestamp, adjustOptions(options)))
		.then((response) => {
			if (config.DEBUG) {
				const contentLength = response.headers.get("CONTENT-LENGTH")
				if (contentLength && contentLength > config.API_LENGTH_THRESHOLD) {
					new ApiError(
						"",
						"",
						{
							errors: [
								{description: `Veľkosť odpovede ${response.url} zo servera presahuje maximálnu povolenú hodnotu (1MB)`}
							]
						},
						additionalInfoForError,
						false
					)
				}
			}

			setExpireToken(response.headers)

			return transform
				? (typeof getStatusCode === "function" ? getStatusCode(response.status) : null,
				  checkStatus(response, additionalInfoForError, hideError))
				: (typeof getStatusCode === "function" ? getStatusCode(response.status) : null, response)
		})
		.then((response) => (transform ? parseJSON(response, getRequestId) : response))
		.catch((response) => {
			handleApiCatch(response, additionalInfoForError, hideError)
		})
}

export const getFilters = (expressions, extras) => {
	const operators = ["="]
	const filters = expressions.map((expr) => {
		if (operators.some((o) => expr.includes(o))) {
			const operator = operators.find((o) => expr.includes(o))
			const exprSplit = expr.split(operator)
			const column = exprSplit[0]
			const values = exprSplit[1]
			const valuesArr = values.split(";")

			const valuesJSON = valuesArr.map((val) => {
				return {id_value: val}
			})

			return {
				associated_column: column,
				predicate: operator,
				values: valuesJSON
			}
		}
	})

	return Object.assign({}, {filters: filters}, extras)
}

export default api

