export default {
	load() {
		return {
			fields: {
				_id: {},
				region: {
					label: "Región",
					rules: "required"
				},
				group: {
					label: "Skupina zariadenia",
					rules: "required"
				},
				allocation: {
					label: "Alokácia",
					type: "string",
					rules: "required"
				},
				validity_from: {
					label: "Platnosť od",
					rules: "required"
				},
				validity_to: {
					label: "Platnosť do",
					rules: "required"
				},
				start: {
					label: "Štart",
					rules: "required"
				}
			}
		}
	}
}

