"use strict"

import React, {useState} from "react"
import {observer} from "mobx-react"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import dvr from "mobx-react-form/lib/validators/DVR"
import {injectIntl} from "react-intl"

import {login} from "../../global/helpers/actions"
import {Grid, Paper, Typography, Box, Link} from "@material-ui/core"

import XsInput from "../../global/ui/xsInput/xsInput"
import XsButton from "../../global/ui/xsButton/xsButton"

import {isPasswordValid} from "../../global/helpers/functions"
// import {FormattedMessage} from "react-intl"
import LoginStore from "./loginStore"

// import RouterStore from "../../global/store/RouterStore"
import GlobalStore from "../../global/store/GlobalStore"

function Login() {
	const hooks = {
		onSubmit(form) {
			const {log_in, password} = form.values()

			if (isEmpty(log_in) || isEmpty(password)) {
				GlobalStore.setNotificationMessage("Skontrolujte povinné polia", "", "warning")

				form.invalidate()
			} else {
				if (!isPasswordValid(password)) {
					GlobalStore.setNotificationMessage(
						"Heslo musí obsahovať aspoň jedno veľké písmeno, jedno malé písmeno a jedno číslo. Minimálna dĺžka hesla je 8 znakov.",
						"",
						"warning"
					)
					form.$("password").invalidate()
				}
			}
		},
		onSuccess(form) {
			const {log_in, password} = form.values()
			login(log_in, password)
		},
		onError() {
			// GlobalStore.setNotificationMessage("Heslo je povinné pole!", "", "warning")
		}
	}

	const [form] = useState(
		new MobxReactForm(
			{
				fields: {
					log_in: {label: "Prihlasovacie meno", rules: "email|string"},
					password: {label: "Heslo", type: "password"}
				}
			},
			{plugins: {dvr: dvr(validatorjs)}, hooks}
		)
	)

	return (
		<Paper variant="elevation" elevation={2} style={{backgroundColor: "transparent"}}>
			<Box p={2}>
				<Grid item container direction="column" spacing={2}>
					<Grid item>
						{/* <Typography variant="body2">
							Ak ste registrovaný, prihláste sa pomocou Vášho e-mailu a hesla, ktoré ste zadali pri registrácii.
						</Typography> */}
					</Grid>
					<Grid item>
						<XsInput field={form.$("log_in")} autoFocus={true} submitAction={(e) => form.onSubmit(e)} />
					</Grid>
					<Grid item>
						<XsInput
							regex={(val) => isPasswordValid(val)}
							type="password"
							field={form.$("password")}
							submitAction={(e) => form.onSubmit(e)}
						/>
					</Grid>
					<Grid item>
						<XsButton text={"Prihlásiť"} onClick={form.onSubmit} />
					</Grid>
					<Grid item>
						<Link variant="body2" className="pointer" onClick={() => (LoginStore.screen = LoginStore.screens.FORGOT)}>
							<c id="Common.label.forgotPsw" />
							Zabudnuté heslo
						</Link>
					</Grid>
					<Grid item>
						<Typography variant="body2">
							{/* Ak ešte nie ste registrovaný, zaregistrujte sa{" "}
							<Link variant="body2" className="pointer bold" onClick={() => RouterStore.push("/register")}>
								TU!
							</Link>  */}
						</Typography>
					</Grid>
				</Grid>
			</Box>
		</Paper>
	)
}

export default injectIntl(observer(Login))

