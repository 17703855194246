"use strict"

import React, {useEffect} from "react"
import {observer} from "mobx-react"
import {injectIntl} from "react-intl"
import {Container, Box, Grid, Typography, Tabs, Tab} from "@material-ui/core"
import SettingsStore from "./settingsStore"
import RegistrationsWithoutRequest from "./registrationsWithoutRequest"
import CallRoundDialog from "./callRoundDialog"

function SettingsContainer() {
	useEffect(() => {
		SettingsStore.getRegistrationsWithoutRequest()
		SettingsStore.tabValue = "registrations"
	}, [])

	const handleChange = (event, newValue) => {
		SettingsStore.tabValue = newValue
	}

	return (
		<Container maxWidth="lg">
			<Grid container justify="space-between" alignItems="center" className="pt-8">
				<Grid item>
					<Typography variant="h4">Nastavenia</Typography>
				</Grid>
			</Grid>
			<Box pt={2}>
				<Tabs value={SettingsStore.tabValue} onChange={handleChange} indicatorColor="primary" textColor="primary">
					<Tab label="registrácie BEZ žiadosti" value="registrations" />
				</Tabs>
				<RegistrationsWithoutRequest />
			</Box>
			<CallRoundDialog />
		</Container>
	)
}

export default injectIntl(observer(SettingsContainer))

