"use strict"

import React, {useEffect, useState} from "react"
import {observer} from "mobx-react"

import fields from "./microelectronicFields"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import api from "../../global/actions/api"

import {Container, Grid, Typography, Link} from "@material-ui/core"

import {FormattedMessage, injectIntl} from "react-intl"
import XsInput from "../../global/ui/xsInput/xsInput"
import XsAutocomplete from "../../global/ui/xsInput/xsAutocomplete"
import XsNumberInput from "../../global/ui/xsInput/xsNumberInput"
import dvr from "mobx-react-form/lib/validators/DVR"
import XsTextArea from "../../global/ui/xsTextArea/xsTextArea"
import GlobalStore from "../../global/store/GlobalStore"
import Dropzone from "react-dropzone"
import XsConfirmationDialog from "../../global/ui/xsDialog/xsConfirmationDialog"
import XsCheckbox from "../../global/ui/xsCheckbox/xsCheckbox"
import XsButton from "../../global/ui/xsButton/xsButton"

import InfoStore from "../../global/store/InfoStore"
import MicroelectronicFormStore from "./microelectronicFormStore"
import RouterStore from "../../global/store/RouterStore"
import XsSearchSelect from "../../global/ui/xsSearchSelect/xsSearchSelect"
import MicroelectronicPrintStore from "./microelectronicPrintStore"
import RegistersCacheStore from "../../global/store/RegistersCacheStore"
import {isPhoneNumberValid} from "../../global/helpers/functions"

function MicroelectronicForm(props) {
	const requiredAttachemnts = ["annexsd"]
	const hooks = {
		onSubmit(form) {
			for (let index in requiredAttachemnts) {
				if (MicroelectronicFormStore[`dropedFiles${index}`].length < 1) {
					form.$("dropzone").invalidate("minimum")
					GlobalStore.setNotificationMessage(
						"Application can't be submitted, no mandatory files attached! Please attach mandatory documents first.",
						null,
						"error"
					)
				}
			}

			if (isNotEmpty(form.$("phone").value)) {
				form.$("phone").value = form.$("phone").value.replace(/ /g, "")
				if (!isPhoneNumberValid(form.$("phone").value)) {
					form.$("phone").invalidate("incorect format")
					GlobalStore.setNotificationMessage("Phone number is not valid!", null, "error")
				}
			}

			if (isNotEmpty(form.$("companyPSC").value)) {
				form.$("companyPSC").value = form.$("companyPSC").value.replace(/ /g, "")
				if (form.$("companyPSC").value.length != 5) {
					form.$("companyPSC").invalidate("incorect format")
					GlobalStore.setNotificationMessage("Zip is not valid!", null, "error")
				}
			}

			if (form.$("activityTypeRD").value == false && form.$("activityTypeFID").value == false) {
				form.$("activityTypeRD").invalidate("minimum one")
				GlobalStore.setNotificationMessage(
					"Application can't be submitted, you need to choose at least one Type of activity",
					null,
					"error"
				)
			}

			if (
				form.$("sectorAutomotive").value == false &&
				form.$("sectorHealth").value == false &&
				form.$("sectorIndustry").value == false &&
				form.$("sectorAdvancedPackagingAndTesting").value == false &&
				form.$("sectorCompoundMaterials").value == false &&
				form.$("sectorTelecommunication").value == false &&
				form.$("sectorAeronautics").value == false &&
				form.$("sectorEnergyEfficiencyChips").value == false &&
				form.$("sectorSemiconductorManufacturing").value == false &&
				form.$("sectorInternetOfThings").value == false &&
				form.$("sectorCyberSecurity").value == false &&
				form.$("sectorSensors").value == false &&
				form.$("sectorOther").value == false
			) {
				form.$("sectorAutomotive").invalidate("minimum one")
				GlobalStore.setNotificationMessage(
					"Application can't be submitted, you need to choose at least one Applicable sector",
					null,
					"error"
				)
			}

			if (
				form.$("beforeTRL1").value == false &&
				form.$("beforeTRL2").value == false &&
				form.$("beforeTRL3").value == false &&
				form.$("beforeTRL4").value == false &&
				form.$("beforeTRL5").value == false &&
				form.$("beforeTRL6").value == false &&
				form.$("beforeTRL7").value == false &&
				form.$("beforeTRL8").value == false &&
				form.$("beforeTRL9").value == false &&
				form.$("afterTRL1").value == false &&
				form.$("afterTRL2").value == false &&
				form.$("afterTRL3").value == false &&
				form.$("afterTRL4").value == false &&
				form.$("afterTRL5").value == false &&
				form.$("afterTRL6").value == false &&
				form.$("afterTRL7").value == false &&
				form.$("afterTRL8").value == false &&
				form.$("afterTRL9").value == false
			) {
				form.$("beforeTRL1").invalidate("minimum one")
				GlobalStore.setNotificationMessage(
					"Application can't be submitted, you need to choose at least one Technology Readiness Level",
					null,
					"error"
				)
			}
		},
		onSuccess(form) {
			MicroelectronicFormStore.generateDataAndSave(form, "final", () => {
				RouterStore.push("/requests")
				GlobalStore.loading(false)
			})
		},
		onError() {
			GlobalStore.setNotificationMessage("Check required fields!", null, "error")
		}
	}

	const [form] = useState(new MobxReactForm(fields.load(), {plugins: {dvr: dvr(validatorjs)}, hooks}))

	useEffect(() => {
		MicroelectronicFormStore.formRef = form
		MicroelectronicFormStore.resetDropzones()
		if (isNotEmpty(props.id)) {
			MicroelectronicFormStore.loadCodelistAndForm(form, props.id)
		} else {
			MicroelectronicFormStore.defaultFormValues = form.values()
			RegistersCacheStore.checkRegisters("/microelectronic")
		}
		MicroelectronicFormStore.loadAttachemntTemplates()
	}, [])

	const handleDrop = (files, index, length) => {
		for (let fileIndex in files) {
			let file = files[fileIndex]
			if (file.size > 500000) {
				GlobalStore.setNotificationMessage("Size limit exceeded!", null, "error")
				continue
			}
			if (MicroelectronicFormStore[`dropedFiles${index}`].length >= length) {
				GlobalStore.setNotificationMessage("Maximum number of files exceeded!", null, "error")
				continue
			}

			if (index == 0) {
				file.databaseType = "annexsd"
			}

			MicroelectronicFormStore[`dropedFiles${index}`].push(file)
		}
	}

	const handleDropzoneDelete = (attachmentIndex, dropzoneIndex) => {
		if (isSafe(MicroelectronicFormStore[`dropedFiles${dropzoneIndex}`][attachmentIndex]._id)) {
			MicroelectronicFormStore.deleteLoadedAttachemnt(
				MicroelectronicFormStore[`dropedFiles${dropzoneIndex}`][attachmentIndex]._id,
				dropzoneIndex,
				attachmentIndex
			)
		} else {
			MicroelectronicFormStore[`dropedFiles${dropzoneIndex}`].splice(attachmentIndex, 1)
		}
	}

	const handleDropzonePreview = (attachmentIndex, dropzoneIndex) => {
		var FileSaver = require("file-saver") // eslint-disable-line no-undef
		FileSaver.saveAs(
			MicroelectronicFormStore[`dropedFiles${dropzoneIndex}`][attachmentIndex],
			MicroelectronicFormStore[`dropedFiles${dropzoneIndex}`][attachmentIndex].name
		)
	}

	const isFormDisabled = () => {
		return (
			Boolean(isNotEmpty(form.$("status").value) && form.$("status").value != "inp") ||
			GlobalStore.isRegistrationClose()
		)
		// return false
	}

	const generateYearItems = () => {
		let data = []
		for (let i = 20; i <= 50; i++) {
			data.push({
				_id: `20${i}`,
				code: `20${i}`,
				name_ext: `20${i}`
			})
		}
		return data
	}

	const generateMonthItems = () => {
		let data = []
		for (let i = 1; i <= 12; i++) {
			data.push({
				_id: `${i < 10 ? "0" : ""}${i}`,
				code: `${i < 10 ? "0" : ""}${i}`,
				name_ext: `${i < 10 ? "0" : ""}${i}`
			})
		}
		return data
	}

	return (
		<React.Fragment>
			<Container maxWidth="lg">
				<Container maxWidth="lg" className="request_form_fields">
					<Grid container direction="column" spacing={2} className="pt-5">
						<Grid item>
							<Typography variant="subtitle2">
								Call for expression of Interest for participation in an Important Projects of Common European Interest
								(IPCEI) on Microelectronics II
							</Typography>
						</Grid>
						<Grid item>
							<Typography variant="body2">
								This call for expression of interest has the scope to establish the feasibility for Slovakia to
								participatre in the forthcoming IPCEI Microelectronics II. Submitting this expression of interest
								provides no guarantee or entitlement to funding. Project funding is subject to a decision participate in
								the IPCEI Microelectronics II following an internal review, budgetary considerations, and the approval
								of any State Aid by the European Commission.
							</Typography>
						</Grid>
						<Grid item>
							<Typography variant="subtitle2">Fields marked with an * are required.</Typography>
						</Grid>
					</Grid>
					<Grid container spacing={2} className="pt-5">
						<Grid item xs={12}>
							<Typography variant="h5">
								<FormattedMessage id="Text.requesterInfo" />
							</Typography>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsInput field={form.$("companyName")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsNumberInput field={form.$("ico")} step={"1"} min={"1"} disabled={isFormDisabled()} />
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Typography variant="h6">Registered seat of company</Typography>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsSearchSelect
								field={form.$("contactCountry")}
								items={GlobalStore.CL["countries"]}
								required
								disabled={isFormDisabled() || isNotEmpty(form.$("_id").value)}
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsSearchSelect
								field={form.$("companyRegion")}
								items={GlobalStore.CL["addressRegion"]}
								required
								disabled={isFormDisabled()}
								onChange={() => {
									form.$("companyCounty").value = ""
									form.$("companyCity").value = ""
								}}
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsSearchSelect
								field={form.$("companyCounty")}
								items={
									isNotEmpty(form.$("companyRegion").value) && isSafe(GlobalStore.CL["addressCounty"])
										? GlobalStore.CL["addressCounty"].filter((row) => {
												return row.region == form.$("companyRegion").value
										  })
										: GlobalStore.CL["addressCounty"]
								}
								required
								disabled={isFormDisabled()}
								onChange={() => {
									form.$("companyCity").value = ""
								}}
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsAutocomplete
								field={form.$("companyCity")}
								minLengthForSearch="1"
								api={api.loadEntityCity}
								inputRenderer={(obj) => obj.name_ext}
								saveValue={(obj) => obj.code}
								disabled={isFormDisabled()}
								addSpecialFilter={() => {
									if (isNotEmpty(form.$("companyCounty").value)) {
										return [
											{
												associated_column: "county",
												predicate: "=",
												values: [
													{
														id_value: form.$("companyCounty").value
													}
												]
											}
										]
									}
								}}
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsInput field={form.$("companyStreet")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsInput field={form.$("companyStreetNumber")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsInput field={form.$("companyPSC")} disabled={isFormDisabled()} />
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Typography variant="h6">
								<FormattedMessage id="Text.contactInfo" />
							</Typography>
						</Grid>

						<Grid item xs={12} md={6} lg={3}>
							<XsInput field={form.$("name")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsInput field={form.$("surname")} disabled={isFormDisabled()} />
						</Grid>

						<Grid item xs={12} md={6} lg={3}>
							<XsInput field={form.$("mail")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsInput field={form.$("phone")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item xs={12}>
							<XsTextArea field={form.$("contactDescription")} disabled={isFormDisabled()} maxLength={3000} />
						</Grid>
					</Grid>
					<Grid container spacing={2} className="pt-5">
						<Grid item xs={12}>
							<Typography variant="h5">
								<FormattedMessage id="Text.projectInfo" />
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<XsInput field={form.$("projectName")} disabled={isFormDisabled()} />
						</Grid>
						{/* <Grid item xs={12}>
							<XsInput field={form.$("projectAcronym")} disabled={isFormDisabled()} />
						</Grid> */}
						<Grid item xs={12}>
							<Typography variant="h6">Location of project implementation </Typography>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsSearchSelect
								field={form.$("applicantCountry")}
								items={GlobalStore.CL["countries"]}
								required
								disabled={isFormDisabled() || isNotEmpty(form.$("_id").value)}
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsSearchSelect
								field={form.$("applicantRegion")}
								items={GlobalStore.CL["addressRegion"]}
								required
								disabled={isFormDisabled()}
								onChange={() => {
									form.$("applicantCity").value = ""
								}}
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsAutocomplete
								field={form.$("applicantCity")}
								minLengthForSearch="1"
								api={api.loadEntityCity}
								inputRenderer={(obj) => obj.name_ext}
								saveValue={(obj) => obj.code}
								disabled={isFormDisabled()}
								addSpecialFilter={() => {
									if (isNotEmpty(form.$("applicantRegion").value)) {
										return [
											{
												associated_column: "region",
												predicate: "=",
												values: [
													{
														id_value: form.$("applicantRegion").value
													}
												]
											}
										]
									}
								}}
							/>
						</Grid>
					</Grid>

					<Grid container direction="column" className="pt-2">
						<Grid item>
							<Typography variant="h6">Type of activity *</Typography>
						</Grid>
						<Grid item>
							<XsCheckbox field={form.$("activityTypeRD")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item>
							<XsCheckbox field={form.$("activityTypeFID")} disabled={isFormDisabled()} />
						</Grid>
					</Grid>
					<Grid container spacing={2} className="pt-2">
						<Grid item xs={12} md={6}>
							<XsSearchSelect
								field={form.$("plannedProjectStartYear")}
								items={generateYearItems()}
								onChange={() => {
									form.$("plannedProjectStart")
								}}
								disabled={isFormDisabled()}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<XsSearchSelect
								field={form.$("plannedProjectStartMonth")}
								items={generateMonthItems()}
								disabled={isFormDisabled()}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<XsNumberInput
								field={form.$("durationInMonth")}
								step={"1"}
								min={"1"}
								endText={"months"}
								disabled={isFormDisabled()}
							/>
							{/* <XsInput field={form.$("durationInMonth")} disabled={isFormDisabled()} /> */}
						</Grid>
					</Grid>
					<Grid container direction="column" className="pt-2">
						<Grid item>
							<Typography variant="h6">Applicable sector *</Typography>
						</Grid>
						<Grid item>
							<XsCheckbox field={form.$("sectorAutomotive")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item>
							<XsCheckbox field={form.$("sectorHealth")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item>
							<XsCheckbox field={form.$("sectorIndustry")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item>
							<XsCheckbox field={form.$("sectorAdvancedPackagingAndTesting")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item>
							<XsCheckbox field={form.$("sectorCompoundMaterials")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item>
							<XsCheckbox field={form.$("sectorTelecommunication")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item>
							<XsCheckbox field={form.$("sectorAeronautics")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item>
							<XsCheckbox field={form.$("sectorEnergyEfficiencyChips")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item>
							<XsCheckbox field={form.$("sectorSemiconductorManufacturing")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item>
							<XsCheckbox field={form.$("sectorInternetOfThings")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item>
							<XsCheckbox field={form.$("sectorCyberSecurity")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item>
							<XsCheckbox field={form.$("sectorSensors")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item>
							<XsCheckbox
								field={form.$("sectorOther")}
								disabled={isFormDisabled()}
								onChange={() => {
									if (form.$("sectorOther").value) {
										form.$("sectorOtherDescription").set("rules", "required")
									} else {
										form.$("sectorOtherDescription").set("rules", "")
										form.$("sectorOtherDescription").value = ""
									}
								}}
							/>
							{form.$("sectorOther").value && (
								<XsTextArea field={form.$("sectorOtherDescription")} disabled={isFormDisabled()} />
							)}
						</Grid>
					</Grid>
					<Grid container spacing={2} className="pt-2">
						<Grid item xs={12} md={6}>
							<XsNumberInput
								field={form.$("plannedInvestment")}
								step={"0.001"}
								min={"0.001"}
								endText={"mil.€"}
								disabled={isFormDisabled()}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<XsNumberInput
								field={form.$("plannedOpex")}
								step={"0.001"}
								min={"0.001"}
								endText={"mil.€"}
								disabled={isFormDisabled()}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={1} className="pt-2">
						<Grid item xs={12}>
							<XsTextArea field={form.$("specificProjectDescription")} maxLength={7500} disabled={isFormDisabled()} />
						</Grid>
						<Grid item xs={12}>
							<XsTextArea
								field={form.$("projectValueForHydrogenEcosystem")}
								maxLength={3000}
								disabled={isFormDisabled()}
							/>
						</Grid>
						<Grid item xs={12}>
							<XsTextArea field={form.$("plannedGoals")} maxLength={3000} disabled={isFormDisabled()} />
						</Grid>
					</Grid>
					<Grid container direction="column" className="pt-2">
						<Grid item>
							<Typography variant="h6">Technology Readiness Level*</Typography>
						</Grid>
						<Grid container item xs={12} justify="space-between" className="pt-2 pb-1">
							<Grid item>At the start of project</Grid>
							<Grid item>After completion of project</Grid>
						</Grid>
						{isSafe(GlobalStore.CL["beforeReadinessLevel"]) &&
							GlobalStore.CL["beforeReadinessLevel"].length &&
							GlobalStore.CL["beforeReadinessLevel"].map((level, idx) => {
								const number = idx + 1

								return (
									<Grid item key={idx} container alignItems="center" className="ml-2">
										<Grid item xs={1} className="textCenter">
											<XsCheckbox field={form.$(`beforeTRL${number}`)} disabled={isFormDisabled()} />
										</Grid>
										<Grid item xs={10} className="textCenter">
											{level.code_ext} - {level.name_ext}
										</Grid>
										<Grid item xs={1} className="textCenter">
											<XsCheckbox field={form.$(`afterTRL${number}`)} disabled={isFormDisabled()} />
										</Grid>
									</Grid>
								)
							})}
					</Grid>
					<Grid container spacing={1} className="pt-2">
						<Grid item xs={12}>
							<XsTextArea field={form.$("projectEnablesPartnersTo")} maxLength={3000} disabled={isFormDisabled()} />
						</Grid>
						<Grid item xs={12}>
							<XsTextArea field={form.$("projectLooksForPartnersFrom")} maxLength={3000} disabled={isFormDisabled()} />
						</Grid>
						<Grid item xs={12}>
							<XsTextArea field={form.$("projectNeedsStatesHelp")} maxLength={3000} disabled={isFormDisabled()} />
						</Grid>
						<Grid item xs={12}>
							<XsTextArea field={form.$("projectAssumptions")} maxLength={3000} disabled={isFormDisabled()} />
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<React.Fragment>
								<Typography variant="body1">
									Please download these mandatory document templates, fill in the documents and attach them to this
									application form - see dropzone below. Max size for each document is 500kB.
								</Typography>
								<Typography variant="body1">
									<b>Only these file types are allowed: doc, docx, xls, xlsx, pdf</b>
								</Typography>
							</React.Fragment>
						</Grid>
						<Grid item container>
							{isSafe(MicroelectronicFormStore.attachmentTemplates) &&
								MicroelectronicFormStore.attachmentTemplates
									.filter((row) => requiredAttachemnts.includes(row.attachment_type_id))
									.map((template, dropzoneIndex) => {
										return (
											<Grid item container spacing={2} key={template.attachment_type_id}>
												<Grid item xs={12}>
													<Link
														variant="body2"
														className="pointer bold"
														onClick={() => {
															delayedCallback(200, () => {
																MicroelectronicFormStore.downloadSpiecificTemplate(template.api_url, template.file_name)
															})
														}}
													>
														<i className="fas fa-file-download mr-1"></i>
														<span>{template.attachment_type_name}</span>
													</Link>
												</Grid>
												<Grid item xs={12}>
													<Dropzone
														onDrop={(files) => {
															handleDrop(files, dropzoneIndex, 1)
														}}
														id="dropzone"
														disabled={isFormDisabled()}
														accept={".doc, .docx, .xls, .xlsx, .pdf"}
													>
														{isSafe(MicroelectronicFormStore[`dropedFiles${dropzoneIndex}`]) &&
														MicroelectronicFormStore[`dropedFiles${dropzoneIndex}`].length ? (
															MicroelectronicFormStore[`dropedFiles${dropzoneIndex}`].map((file, index) => (
																<div key={index} className={"dropedFile"}>
																	<i
																		className="fal fa-file icon"
																		onClick={(e) => {
																			e.stopPropagation()
																			handleDropzonePreview(index, dropzoneIndex)
																		}}
																	/>
																	<i
																		className="far fa-trash-alt deleteIcon"
																		onClick={(e) => {
																			e.stopPropagation()
																			if (!isFormDisabled()) {
																				GlobalStore.openConfirmationDialog("xsDeleteAttachmentFromDropzone", {
																					fileIndex: index,
																					dropzoneIndex: dropzoneIndex
																				})
																			}
																		}}
																	/>
																	<br />
																	<div
																		className={"mac_ellipsis"}
																		data-content-start={file.name.substr(0, Math.floor(file.name.length / 2))}
																		data-content-end={
																			"\u200E" + file.name.substr(Math.floor(file.name.length / 2)) + "\u200E"
																		}
																	/>
																</div>
															))
														) : (
															<div
																className={"dropzonePlaceholder"}
															>{`Drop ${template.attachment_type_name} here or click to upload file`}</div>
														)}
													</Dropzone>
												</Grid>
											</Grid>
										)
									})}
						</Grid>
					</Grid>
				</Container>
				<Container maxWidth="lg" className="request_form_buttons">
					<Grid item container spacing={1}>
						<Grid item>
							<XsButton
								icon={<i className="fal fa-save"></i>}
								onClick={() => {
									delayedCallback(300, () => {
										MicroelectronicFormStore.validateDraft(form, () => {
											MicroelectronicFormStore.generateDataAndSave(form, "draft", (id) => {
												RouterStore.push(`/microelectronic?id=${id}`)
												GlobalStore.loading(true)
												MicroelectronicFormStore.resetDropzones()
												form.reset()
												MicroelectronicFormStore.load(form, id)
											})
										})
									})
								}}
								disabled={
									!(form.$("status").value == "inp" || isEmpty(form.$("status").value)) ||
									GlobalStore.isRegistrationClose()
								}
								text={<FormattedMessage id="Common.btn.save" />}
							/>
						</Grid>
						<Grid item>
							<XsButton
								icon={<i className="fal fa-save"></i>}
								onClick={(e) => {
									delayedCallback(300, () => {
										form.onSubmit(e)
									})
								}}
								disabled={form.$("status").value == "sent" || GlobalStore.isRegistrationClose()}
								text="Submit (no further changes to form possible)"
							/>
						</Grid>
						<Grid item>
							<XsButton
								disabled={form.$("status").value != "sent"}
								icon={<i className="fal fa-print"></i>}
								onClick={() => {
									delayedCallback(300, () => {
										MicroelectronicPrintStore.print(props.id)
										InfoStore.open(
											"Please enable pop-up windows (tabs) for this site, if new tab with generated PDF is not shown",
											<FormattedMessage id="Common.label.warning" />
										)
									})
								}}
								variant="outlined"
								text={<FormattedMessage id="Common.btn.print" />}
							/>
						</Grid>
						<Grid item>
							<XsButton
								icon={<i className="fal fa-times"></i>}
								onClick={() => {
									delayedCallback(300, () => {
										if (MicroelectronicFormStore.isSameFormValues()) {
											form.reset()
											RouterStore.push("/requests")
										} else {
											GlobalStore.openConfirmationDialog("xsCloseMicroelectronicForm")
										}
									})
								}}
								color="secondary"
								text={<FormattedMessage id="Common.btn.close" />}
							/>
						</Grid>
					</Grid>
				</Container>
			</Container>
			<XsConfirmationDialog
				name="xsDeleteAttachmentFromDropzone"
				title={<FormattedMessage id="Common.label.warning" />}
				text={<FormattedMessage id="Common.label.deleteAttachment" />}
				onConfirmation={() =>
					handleDropzoneDelete(
						GlobalStore.confirmationDialogParams.fileIndex,
						GlobalStore.confirmationDialogParams.dropzoneIndex
					)
				}
			/>
			<XsConfirmationDialog
				name="xsCloseMicroelectronicForm"
				text="Are you sure you want to leave and discard recent changes?"
				confirmBtn={{
					icon: <i className="fal fa-check" />,
					text: "Common.btn.yes"
				}}
				cancelBtn={{
					icon: <i className="fal fa-times" />,
					text: "Common.btn.no"
				}}
				onConfirmation={() => {
					form.reset()
					RouterStore.push("/requests")
				}}
			/>
		</React.Fragment>
	)
}

export default injectIntl(observer(MicroelectronicForm))

