"use strict"

import {observable, action} from "mobx"
import api from "../../global/actions/api"
import GlobalStore from "../../global/store/GlobalStore"
import SettingsStore from "./settingsStore"

class CallRoundDialogStore {
	@observable isOpen = false

	formRef = null

	@action open(row) {
		this.isOpen = true

		if (isNotEmpty(row)) {
			this.formRef.$("_id").value = row._id
			this.formRef.$("region").value = row.region_id
			this.formRef.$("group").value = row.device_group_id
			this.formRef.$("allocation").value = row.allocation
			this.formRef.$("validity_from").value = row.validity_from
			this.formRef.$("validity_to").value = row.validity_to
			this.formRef.$("start").value = row.start
		}
	}

	@action close() {
		this.isOpen = false
	}

	@action addCallRound(form) {
		const values = form.values()

		const _id = isNotEmpty(values._id) ? values._id : null

		const req = {
			_id: _id,
			region_type_id: values.region,
			devide_group_id: values.group,
			validity_to: values.validity_to,
			validity_from: values.validity_from,
			allocation: values.allocation,
			start: values.start
		}

		api
			.addRound(req)
			.call()
			.then(() => {
				GlobalStore.setNotificationMessage(`Kolo výzvy úspešne ${isNotEmpty(_id) ? "upravené" : "pridané"}`)
				SettingsStore.getSettings()

				form.reset()

				this.close()
			})
	}
}

var singleton = new CallRoundDialogStore()
export default singleton

